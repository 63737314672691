import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Button, CircularProgress, Typography, Dialog, DialogTitle, DialogContent, DialogActions, MobileStepper } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';

const PREFIX = 'HelpModal';

const classes = {
    content: `${PREFIX}-content`,
    img: `${PREFIX}-img`,
    actions: `${PREFIX}-actions`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
    [`& .${classes.content}`]: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    [`& .${classes.img}`]: {
        maxHeight: '65vh',
        maxWidth: '65vw',
        width: '100%',
        height: '100%'
    },
    [`& .${classes.actions}`]: {
        display: 'block'
    }
});

const HelpModal = React.memo(({ slides }) => {


    const [open, setOpen] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const {t} = useTranslation('buttons');

    useEffect(() => {
        if (!open) setActiveStep(0)
    }, [open])

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleNext = useCallback(() => {
        setActiveStep(prev => prev + 1)
    }, [])

    const handleBack = useCallback(() => {
        setActiveStep(prev => prev - 1)
    }, [])

    return (
        (<Root>
            <IconButton size="small" onClick={handleOpen}>
                <HelpOutlineIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{t('help_text')}</DialogTitle>
                <DialogContent className={classes.content}>
                    {/* <div> */}
                        {slides[activeStep].img && <img
                            className={classes.img}
                            src={slides[activeStep].img}
                        />}
                        <Typography>{slides[activeStep].text}</Typography>
                        {/* <MobileStepper
                        steps={slides.length}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" onClick={activeStep === slides.length - 1 ? handleClose : handleNext}>
                                {activeStep === slides.length - 1 ? <>
                                    OK
                                </> : <>
                                    Next
                                    <KeyboardArrowRight />
                                </>}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                <KeyboardArrowLeft />
                                Back
                            </Button>
                        }
                    /> */}
                    {/* </div> */}
                </DialogContent>
                <DialogActions className={classes.actions}>
                    {/* <Button onClick={handleClose} color="primary" disabled={true}>
                        OK
                    </Button> */}
                    <MobileStepper
                        steps={slides.length}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" sx={{padding: '0px 20px'}} onClick={activeStep === slides.length - 1 ? handleClose : handleNext}>
                                {activeStep === slides.length - 1 ? <>
                                    {t('OK_button')}
                                </> : <>
                                    {t('NEXT_button')}
                                    <KeyboardArrowRight />
                                </>}
                            </Button>
                        }
                        backButton={
                            <Button size="small" sx={{padding: '0px 20px'}} onClick={handleBack} disabled={activeStep === 0}>
                                <KeyboardArrowLeft />
                                {t('back_button')}
                            </Button>
                        }
                    />
                </DialogActions>
            </Dialog>
        </Root>)
    );

})

export default HelpModal;