import config from '../config.json'

export const getChatCredentialPromise = (username, instanceId, token, sessionId) =>
    fetch(`https://${config.rest.vwChimeAPI}/chat?username=${username}&instanceId=${instanceId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token + ' ' + sessionId
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

