import React, { useCallback, useState } from 'react'
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/material/styles';
import * as he from 'he';

const PREFIX = 'TriviaQuestion';

const classes = {
    triviaContainer: `${PREFIX}-triviaContainer`,
    questionContainer: `${PREFIX}-questionContainer`,
    questionSpacer: `${PREFIX}-questionSpacer`,
    questionBox: `${PREFIX}-questionBox`,
    questionMessage: `${PREFIX}-questionMessage`,
    questionButton: `${PREFIX}-questionButton`,
    nextButton: `${PREFIX}-nextButton`,
    nextBox: `${PREFIX}-nextBox`,
    answerButton: `${PREFIX}-answerButton`
};

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    /* height: 60%; */
    /* flex-basis: 65%; */
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: '0 1em 0 1em',
    boxSizing: 'border-box',

    [`& .${classes.questionContainer}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        position: 'relative',
    },
    [`& .${classes.questionSpacer}`]: {
        position: 'relative',
        /* display: flex;
        align-items: center;
        justify-content: flex-end; */
        marginBottom: 'auto',
    },
    [`& .${classes.questionBox}`]: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        height: '100%',
    },
    [`& .${classes.questionMessage}`]: {
        position: 'relative',
        margin: '0.5rem 0 0.5rem 0',
        width: '130%',
        textAlign: 'center'
    },
    [`& .${classes.questionButton}`]: {
        /* border: 2px solid; */
        borderRadius: '6px',
        // fontSize: 'calc(20px + (24 - 20) * ((100vw - 300px) / (1600 - 300)))',
        color: 'white',
        // margin: '2vw',
        // padding: '0.25em 0.5em 0.25em 0.5em',
        verticalAlign: 'middle'
    },
    [`& .${classes.nextButton}`]: {
        borderRadius: '6px',
        // fontSize: 'calc(20px + (24 - 20) * ((100vw - 300px) / (1600 - 300)))',
        /* color: white; */
        // margin: '2vw',
        // padding: '0.25em 0.5em 0.25em 0.5em',
        verticalAlign: 'middle',
    },
    [`& .${classes.nextBox}`]: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // marginTop: 'auto',
        margin: '2vh',
        boxSizing: 'border-box'
    },
    [`& .${classes.answerButton}`]: {
        borderRadius: '6px',
        width: '100%',
        // fontSize: 'calc(12px + (16 - 20) * ((100vw - 300px) / (1600 - 300)))',
        // color: 'white',
        // margin: '2%',
        // padding: '0.25em 0.5em 0.25em 0.5em',
        verticalAlign: 'middle',
        margin: '3px 0 3px 0'
    }
});

const TriviaQuestion = React.memo(({ value: question, answers, correctAnswer, buttonLabel, onSubmit }) => {
    const [selectedAnswer, setSelectedAnswers] = useState()
    const [showCorrect, setShowCorrect] = useState(false)

    const theme = useTheme()

    const handleAnswer = useCallback((answer) => {
        // setSelectedAnswers(prev => {
        //     if (multiple) {
        //         return prev.includes(answer) ? prev.filter(x => x !== answer) : [...prev, answer]
        //     } else {
        //         return answer
        //     }
        // })
        setSelectedAnswers(answer)
    }, [])

    return (
        <Root>
            <div className={classes.questionContainer}>
                <Typography className={classes.questionMessage}>{he.decode(question)}</Typography>
                {/* <div className={classes.questionMessage}>{he.decode(question)}</div> */}
                <div className={classes.questionBox}>
                    {answers.map(answer =>
                        <Button disabled={showCorrect} color={"primary"} className={classes.answerButton} variant="contained" style={{
                            boxSizing: 'border-box',
                            // border: showCorrect && answer === selectedAnswer ? answer === correctAnswer ? '2px solid green' : '2px solid red' : '2px solid transparent',
                            border: `3px solid ${answer === selectedAnswer ? showCorrect ? answer === correctAnswer ? theme.palette.success.main : theme.palette.error.main : theme.palette.primary.dark : 'transparent'}`,
                            backgroundColor: answer === selectedAnswer ? theme.palette.primary.light : showCorrect && answer === correctAnswer ? theme.palette.success.main : theme.palette.primary.main,
                            // backgroundColor: showCorrect ? answer === correctAnswer && selectedAnswer !== answer ? theme.palette.success.main : theme.palette.primary.dark : 'default',
                            color: theme.palette.primary.contrastText,
                            boxShadow: `${selectedAnswer === answer ? 'inset ' : ''}1px 1px 3px rgba(0,0,0,0.5)`
                        }} key={`${answer}button`} onClick={() => handleAnswer(answer)}>{he.decode(answer)}</Button>
                    )}
                </div>
            </div>
            <div className={classes.nextBox}>
                <Button color="primary" className={classes.nextButton} variant="contained" disabled={!selectedAnswer || showCorrect} onClick={() => {
                    setShowCorrect(true)
                    onSubmit(selectedAnswer === correctAnswer)
                }}>{buttonLabel}</Button>
            </div>
        </Root>
    );

})

export default TriviaQuestion