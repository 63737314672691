import React, { useEffect, useRef, useCallback, useState } from 'react'
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import TriviaQuestion from './TriviaQuestion'
import { getQuestions, Session } from 'open-trivia-db';
import './TriviaQuestions.css'
import { Typography, CircularProgress } from '@mui/material';
import { useLocalStorage } from './hooks';
import { useTranslation } from 'react-i18next';

const PREFIX = 'TriviaQuestions';

const classes = {
    trivia: `${PREFIX}-trivia`,
    score: `${PREFIX}-score`
};

const Root = styled('div')({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '400px',
    // height: '420px',
    padding: '0.5rem',
    boxSizing: 'border-box',
    [`& .${classes.score}`]: {
        textAlign: 'right',
        padding: '0 1em 0 1em',
        width: '100%'
    }
});

const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
    }
    return array;
};

const TriviaQuestions = React.memo(({ sessionId, techCheck }) => {
    const [triviaQuestions, setTriviaQuestions] = useState()
    const [currentQuestion, setCurrentQuestion] = useState()
    const [triviaScore, setTriviaScore] = useLocalStorage(`triviascore${sessionId}${techCheck ? '-TC' : ''}`, 0)
    const [index, setIndex] = useState(0)
    const { t } = useTranslation('session_video');



    const questions = useRef()
    const token = useRef()

    const getTrivia = useCallback((uniqueToken) => {
        const triviaOptions = {
            amount: 10,
            category: "Entertainment: Film",
            difficulty: 'medium',
            type: 'multiple',
            session: uniqueToken
        }
        return getQuestions(triviaOptions).then(trivia => {
            console.log(trivia);
            // setTriviaQuestions(trivia)
            return trivia
        });
    }, [])

    const handleSubmit = useCallback((isCorrect) => {
        console.log('submit', isCorrect)
        setTriviaScore(prev => isCorrect ? prev + 5 : prev)
        setTimeout(() => {
            setIndex(prev => prev + 1)
            // const nextQuestion = questions.current[0]
            // console.log(nextQuestion)
            // nextQuestion.answers = shuffle([...nextQuestion.incorrectAnswers, nextQuestion.correctAnswer])
            // setCurrentQuestion(questions.current[0])
            setTriviaQuestions(prev => {
                console.log(prev)
                const questions = [...prev]
                const nextQuestion = questions.shift()
                console.log(nextQuestion)
                nextQuestion.answers = shuffle([...nextQuestion.incorrectAnswers, nextQuestion.correctAnswer])
                setCurrentQuestion(nextQuestion)
                return [...questions]
            })
        }, 1500)
    }, [setTriviaScore])

    useEffect(() => {
        const mySession = new Session();
        mySession.start().then(uniqueToken => {
            token.current = uniqueToken
            return getTrivia(token.current)
        }).then((trivia) => {
            const firstQuestion = trivia.shift()
            setTriviaQuestions(trivia)
            firstQuestion.answers = shuffle([...firstQuestion.incorrectAnswers, firstQuestion.correctAnswer])
            setCurrentQuestion(firstQuestion)
        })
    }, [getTrivia])

    useEffect(() => {
        if (triviaQuestions) {
            questions.current = triviaQuestions
            if (triviaQuestions.length === 2) {
                console.log("GETTING MORE")
                getTrivia(token.current).then((trivia) => {
                    setTriviaQuestions(prev => [...prev, ...trivia])
                })
            }
            // else if (triviaQuestions.length === 0) {

            // }
        }
    }, [triviaQuestions, getTrivia])

    return (
        <Root>
            {(triviaQuestions && triviaQuestions.length && currentQuestion) ? <>
                <Typography variant="overline" className={classes.score}>{t('current_score')} {triviaScore}</Typography>
                <SwitchTransition mode='out-in'>
                    <CSSTransition
                        key={`trivia${index}`}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                        classNames="questions"
                    >
                        <TriviaQuestion onSubmit={handleSubmit} {...currentQuestion} correctAnswer={currentQuestion.correctAnswer} buttonLabel={t('submit_text')} />
                    </CSSTransition>
                </SwitchTransition>
            </> : <CircularProgress />}
        </Root>
    );

})

export default TriviaQuestions