import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import videojs from 'video.js';
import 'videojs-contrib-eme';
import 'videojs-overlay';
import UAParser from 'ua-parser-js'
import { useEventListener } from './hooks'
import { Card, Button, IconButton, CircularProgress } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
// import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { makeStyles } from '@mui/material/styles';
import * as workerTimers from 'worker-timers'
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!style-loader!css-loader!video.js/dist/video-js.css'
import { useTranslation } from 'react-i18next';

const PREFIX = 'TicktBoxVideoPlayer';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  customOverlay: `${PREFIX}-customOverlay`,
  resume: `${PREFIX}-resume`,
  fullscreenButton: `${PREFIX}-fullscreenButton`,
  video: `${PREFIX}-video`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({aspectRatio, wmOpacity}) => ({
  height: '100%',
  width: '100%',
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
    width: '35%',
    minWidth: '160px',
    maxWidth: '260px'
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.customOverlay}`]: {
    // color: 'white !important',
    backgroundColor: 'transparent !important',
    height: `100% !important`,
    width: `100% !important`,
    marginTop: 'auto !important',
    marginBottom: 'auto !important',
    padding: '0 !important',
    top: '0 !important',
    left: '0 !important',
    marginLeft: '0 !important',
    // display: 'flex !important',
    display: 'block !important',
    position: 'absolute !important',
    // alignItems: 'center !important',
    // justifyContent: 'center !important',
    opacity: (wmOpacity && `${wmOpacity / 100} !important`) || '0.15 !important',
    // fontSize: 'calc(9px + (140 - 9) * ((100vw - 150px) / (2400 - 150))) !important',
    fontSize: '1em',
    userSelect: 'none !important',
    MozUserSelect: 'none !important',
    WebkitUserSelect: 'none !important',
    msUserSelect: 'none !important',
    // transform: 'translateY(-50%) rotate(-20.5deg) !important',
    // pointerEvents: 'none !important'
  },
  [`& .${classes.resume}`]: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    // backgroundColor: '#151515'
  },
  [`& .${classes.fullscreenButton}`]: {
    // position: 'absolute',
    // bottom: 0,
    // right: 0,
    color: 'white',
    zIndex: 2,
    opacity: 0.25,
    '&:hover': {
      opacity: 1
    },
    mixBlendMode: 'difference'
  },
  [`& .${classes.video}`]: {
    maxWidth: aspectRatio ? `${1 / aspectRatio * 100}vh` : 0,
    maxHeight: aspectRatio ? `${aspectRatio * 100}vw` : 0,
    width: aspectRatio ? '100%' : 0,
    height: aspectRatio ? '100%' : 0,
    flex: 1
  }
}));

const parser = new UAParser();

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const base64DecodeUint8Array = (input) => {
  const raw = window.atob(input);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++)
    array[i] = raw.charCodeAt(i);

  return array;
}

const base64EncodeUint8Array = (input) => {
  const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  let output = "";
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  let i = 0;

  while (i < input.length) {
    chr1 = input[i++];
    chr2 = i < input.length ? input[i++] : Number.NaN;
    chr3 = i < input.length ? input[i++] : Number.NaN;

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
      keyStr.charAt(enc3) + keyStr.charAt(enc4);
  }
  return output;
}

// const isBrowser = (u) => {
//   for (let i = 0; i < navigator.plugins.length; i++) {
//     if (navigator.plugins[i].name != null && navigator.plugins[i].name.substr(0, u.length) === u) {
//       return true;
//     }
//   }
//   return false;
// }

const isBrowser = (u) => {
  if (navigator.userAgentData) {
    for (let brand_version_pair of navigator.userAgentData.brands) {
      if (brand_version_pair.brand.substr(0, u.length) === u) {
        return true;
      }
    }
    return false;
  }
  return false;
}

const browserCheck = async (uaName) => {
  const isBrave = (navigator.brave && (await navigator.brave.isBrave()) || false)
  const browsers = {
    Brave: isBrave,
    Firefox: !!window.InstallTrigger,
    Safari: !!window.ApplePaySession || /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || window.safari.pushNotification),
    Opera: !!window.opr || !!window.opera || isBrowser("Opera"),
    Edge: isBrowser("GREASE") || isBrowser("Microsoft Edg"),
    Chrome: isBrowser("Google Chrome"),
    Chromium: isBrowser("Chromium"),
    // Chrome: !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime),
  }
  const detectedBrowser = Object.keys(browsers).find(k => !!browsers[k])
  return detectedBrowser || uaName
}

const keySystems = {
  // PlayReady: 'com.microsoft.playready',
  Widevine: 'com.widevine.alpha',
  PlayReady: 'com.microsoft.playready',
  // clearkey: ['webkit-org.w3.clearkey', 'org.w3.clearkey'],
  // primetime: ['com.adobe.primetime', 'com.adobe.access'],
  FairPlay: 'com.apple.fps.1_0'
};

// EME Check
const supportedEncryptedMediaExtensions = async (testVideoElement) => {
  if (window.navigator.requestMediaKeySystemAccess) {
    if (typeof window.navigator.requestMediaKeySystemAccess === 'function') {
      console.log('found default EME');
      const isKeySystemSupported = async (keySystem) => {
        const config = [{
          initDataTypes: ['cenc'],
          audioCapabilities: [{
            contentType: "audio/mp4;codecs=\"mp4a.40.2\""
          }],
          videoCapabilities: [{
            contentType: "video/mp4;codecs=\"avc1.42E01E\""
          }]
        }];
        try {
          console.log('CHECKING FOR', keySystems[keySystem])
          await window.navigator.requestMediaKeySystemAccess(keySystems[keySystem], config)
          return keySystem
        } catch (ex) {
          return null
        }
      }
      const supportedSystems = await Promise.all(Object.keys(keySystems).map(isKeySystemSupported))
      return supportedSystems.filter(x => !!x)
    }
  } else if (window.MSMediaKeys) {
    if (typeof window.MSMediaKeys === 'function') {
      console.log('found MS-EME');
      const supportedSystems = Object.keys(keySystems).filter(keysys => window.MSMediaKeys.isTypeSupported(keySystems[keysys]))
      return supportedSystems
    }
  } else if (testVideoElement.webkitGenerateKeyRequest) {
    if (typeof testVideoElement.webkitGenerateKeyRequest === 'function') {
      console.log('found WebKit EME');
      const supportedSystems = Object.keys(keySystems).filter(keysys => testVideoElement.canPlayType('video/mp4', keySystems[keysys]))
      return supportedSystems
    }
  } else {
    console.log('no supported EME implementation found');
    return []
  }
}

const overlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%' viewBox='0 0 200 100'>    
      <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
              <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
              <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
              <feGaussianBlur in="map" stdDeviation="0.15" />
      </filter>
      <text x="50%" y="50%" transform="rotate(-${Math.atan(height / width) * 180 / Math.PI}, 100, 50)" filter="url(#filter)" textLength="200" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
    </svg>
  </svg>`
}

const tiledOverlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%'>
      <defs>
        <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
          <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
          <feGaussianBlur in="map" stdDeviation="${height / 2000} ${width / 2000}" />
        </filter>
        <pattern id="p1" height='25%' width='50%' style="font-size: ${height / 22}px" patternUnits="userSpaceOnUse" patternTransform="rotate(-18.43494882292201)">    
          <text x="25%" y="12.5%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
        </pattern>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#p1)" filter="url(#filter)"></rect>
    </svg>
  </svg>`
}

const TicktBoxVideoPlayer = React.memo(({ videoRef, fluid, fill, fullscreen, allowFullscreen, isFullscreen, toggleFullScreen, fadeOut, autoStop, videoStopTime, techCheck, wm, wmOpacity, watermarkStyle, videoStreamData, sessionId, alreadyStarted, videoTime, sessionStartTime, videoDuration, unloadTime, onStarted, onPlay, onBuffer, onTimeUpdate, onUnload, onMetadataLoaded, onVideoLoaded, onVisiblityChange, onFinished, onVideoError, hasOnScreenDial }) => {

  const [playerLoaded, setPlayerLoaded] = useState(false)
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [resuming, setResuming] = useState(true)
  const [aspectRatio, setAspectRatio] = useState()
  const {t} = useTranslation('video_player');



  const visibilityChangeFn = () => { }

  const videoNodeRef = useRef()
  const playerRef = useRef()
  const timerRef = useRef()
  const stopTimeRef = useRef(videoStopTime)
  // const fadeRef = useRef()
  // const volumeRef = useRef(100)
  const drmTypeRef = useRef()

  useEffect(() => {
    stopTimeRef.current = videoStopTime
  }, [videoStopTime])

  const overlayFn = useMemo(() => {
    return watermarkStyle === 'Tiled' ? tiledOverlay : overlay
  }, [watermarkStyle])

  useEffect(() => {
    playerRef.current = videojs(videoNodeRef.current, {
      controls: false, fluid: false, fill: true
    }, () => {
      supportedEncryptedMediaExtensions(videoNodeRef.current).then(eme => {
        if (!eme.length) {
          console.log("No EMEs Found...using browser defaults")
          parser.setUA(navigator.userAgent);
          const broswerInfo = parser.getResult()
          return browserCheck(broswerInfo.browser.name).then((browser) => {
            console.log('detected browser: ', browser)
            return (browser === 'Safari' ? 'FairPlay' : 'Widevine')
          })
        } else {
          console.log('ALL EMES', eme)
          return eme[0]
        }
      }).then((drm) => {
        drmTypeRef.current = drm
        console.log('USING DRM TYPE: ', drmTypeRef.current)
        playerRef.current.eme()

        //CONFIGURE PLAYER EVENTS ONCE
        playerRef.current.tech_.el_.disablePictureInPicture = true
        playerRef.current.tech_.el_.controls = false
        playerRef.current.tech_.el_.blur();
        playerRef.current.tech_.el_.addEventListener('focus', (e) => {
          e.currentTarget.blur()
          console.log('video focus')
        })

        playerRef.current.el_.blur();
        playerRef.current.el_.addEventListener('focus', (e) => {
          e.currentTarget.blur()
          console.log('videojs focus')
        })

        playerRef.current.on('play', (event) => {
          console.log('play event')
          if (onStarted) onStarted()
        })

        playerRef.current.on('pause', () => {
          if (!playerRef.current.ended()) playerRef.current.play()
          console.log('pause event')
        })

        playerRef.current.on('ended', () => {
          console.log('end event')
          if (onFinished) onFinished()
        })

        playerRef.current.on('timeupdate', (e) => {
          playerRef.current.overlay({
            content: '',
            class: classes.customOverlay,
            overlays: [{
              align: "center",
              content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
              start: 'play',
              end: 'ended'
            }]
          });
          if (onTimeUpdate) onTimeUpdate(e)
          if (stopTimeRef.current && e.target.player.currentTime() >= stopTimeRef.current && onFinished) onFinished()
        })

        playerRef.current.on('seeked', () => {
          console.log('seeked event')
          // if (videoTime && !loadedRef.current) {
          //   console.log('FAST forwarding')
          //   console.log(videoTime, unloadTime, videoTime + (Date.now() - unloadTime) / 1000)
          //   playerRef.current.currentTime(videoTime + (Date.now() - unloadTime) / 1000)
          //   loadedRef.current = true
          // }
        })

        playerRef.current.on('error', (e) => {
          console.log('error event', e)
          if (onVideoError) onVideoError(playerRef.current.error())
        })

        playerRef.current.on('seeking', () => {
          console.log('seeking event')
        })

        playerRef.current.on('loadedmetadata', () => {
          console.log('loadedmetadata event')
          playerRef.current.overlay({
            content: '',
            class: classes.customOverlay,
            overlays: [{
              align: "center",
              content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
              start: 'play',
              end: 'ended'
            }]
          });
          setAspectRatio(playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
          if (onMetadataLoaded) onMetadataLoaded(playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
        })

        playerRef.current.on('loadeddata', () => {
          console.log('loadeddata event')
          if (onVideoLoaded) onVideoLoaded()
          if (!alreadyStarted) setResuming(false)
          setVideoLoaded(true)
        })

        playerRef.current.on('playing', (event) => {
          console.log('playing event')
          if (onPlay) onPlay()
        })

        playerRef.current.on('durationchange', (e) => {
          console.log('durationchange event', e)
        })

        playerRef.current.on('waiting', () => {
          console.log('waiting event')
          if (onBuffer) onBuffer()
        })

        setPlayerLoaded(true)
        // videoStreamData(drmType).then(({ url, drmToken }) => {
        //   afterLoaded(url, drmType, drmToken)
        // }).catch((err) => {
        //   console.log('ERROR FETCHING STREAM DATA: ', err)
        // })
      }).catch((er) => {
        console.log('ERROR GETTING EMEs: ', er)
      })
    })
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose()
      }
    }
  }, [alreadyStarted, onBuffer, onFinished, onMetadataLoaded, onPlay, onStarted, onTimeUpdate, onVideoError, onVideoLoaded, classes.customOverlay, wm, overlayFn])

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
  }, [onVisiblityChange])

  // if (onVisiblityChange) {
  useEventListener('blur', onVisiblityChange || visibilityChangeFn, true);
  useEventListener('focus', onVisiblityChange || visibilityChangeFn, true);
  useEventListener(visibilityChange, onVisiblityChange || visibilityChangeFn, true);
  // }

  const handleResume = useCallback(() => {
    const now = Date.now()
    // if (videoTime && unloadTime) {
    //   if (onFinished && (videoTime + (now - unloadTime)) >= ((videoStopTime * 1000) || videoDuration)) {
    //     onFinished()
    //   } else {
    //     playerRef.current.currentTime(videoTime + (now - unloadTime) / 1000)
    //   }
    // } else {
    //   if (onFinished && ((now - sessionStartTime)) >= ((videoStopTime * 1000) || videoDuration)) {
    //     onFinished()
    //   } else {
    //     playerRef.current.currentTime((now - sessionStartTime) / 1000)
    //   }
    // }
    if ((sessionStartTime && onFinished) && ((now - sessionStartTime)) >= ((stopTimeRef.current * 1000) || videoDuration)) {
      onFinished()
    } else {
      playerRef.current.currentTime((now - (sessionStartTime || 0)) / 1000)
      // if (allowFullscreen && !isFullscreen) toggleFullScreen()
    }
    playerRef.current.play()
    setResuming(false)
  }, [onFinished, sessionStartTime, videoDuration])

  useEffect(() => {
    const now = Date.now()
    // if (sessionStartTime) {
    //   console.log('COMPLETE CHECK', sessionStartTime, videoStopTime, videoDuration, videoTime, unloadTime)
    //   if (videoTime && unloadTime) {
    //     if (onFinished && (videoTime + (now - unloadTime)) >= ((videoStopTime * 1000) || videoDuration)) {
    //       onFinished()
    //     }
    //   } else {
    //     if (onFinished && ((now - sessionStartTime)) >= ((videoStopTime * 1000) || videoDuration)) {
    //       onFinished()
    //     }
    //   }
    // }
    if (!techCheck && (sessionStartTime && onFinished) && ((now - sessionStartTime)) >= ((stopTimeRef.current * 1000) || videoDuration)) {
      onFinished()
    }
  }, [onFinished, techCheck, sessionStartTime, videoDuration])

  useEffect(() => {
    if (fadeOut && playerRef.current) {
      timerRef.current = workerTimers.setInterval(() => {
        const currentVolume = playerRef.current.volume()
        const newVolume = currentVolume - 0.05
        currentVolume > 0 ? playerRef.current.volume(newVolume <= 0 ? 0 : newVolume) : workerTimers.clearInterval(timerRef.current)
      }, 200)
    }
  }, [fadeOut])

  const afterLoaded = useCallback((videoUrl, drmType, drmToken) => {
    const keySystemsParams = {
      [keySystems[drmType]]: drmType !== 'FairPlay' ? {
        url: 'https://license.pallycon.com/ri/licenseManager.do',
        licenseHeaders: {
          'pallycon-customdata-v2': drmToken
        }
      } : {
        getCertificate: (emeOptions, callback) => {
          console.log('GETTING FAIRPLAY CERT')
          videojs.xhr({
            url: 'https://license.pallycon.com/ri/fpsKeyManager.do?siteId=K4O6',
            method: 'GET',
          }, (err, response, responseBody) => {
            if (err) {
              callback(err)
              return
            }
            callback(null, base64DecodeUint8Array(responseBody));
          })
        },
        getContentId: (emeOptions, initData) => {
          console.log('GETTING FAIRPLAY CONTENT ID')
          var uint16array = new Uint16Array(initData.buffer);
          const contentId = String.fromCharCode.apply(null, uint16array);
          return contentId.substring(contentId.indexOf('skd://') + 6);
        },
        getLicense: (emeOptions, contentId, keyMessage, callback) => {
          console.log('GETTING FAIRPLAY LICENSE')
          videojs.xhr({
            url: 'https://license.pallycon.com/ri/licenseManager.do',
            method: 'POST',
            responseType: 'text',
            body: 'spc=' + base64EncodeUint8Array(keyMessage),
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
              'pallycon-customdata-v2': drmToken
            }
          }, (err, response, responseBody) => {
            if (err) {
              callback(err)
              return
            }
            callback(null, base64DecodeUint8Array(responseBody))
          })
        }
      }
    }
    playerRef.current.src({
      src: videoUrl,
      type: drmType === 'FairPlay' ? 'application/x-mpegurl' : 'application/dash+xml',
      withCredentials: true,
      keySystems: keySystemsParams
    })
    // playerRef.current.load()
    if (videoRef) videoRef.current = playerRef.current //to get time data

    playerRef.current.controls(false);
    if (fullscreen) playerRef.current.enterFullWindow();
    playerRef.current.autoplay(false)
    playerRef.current.muted(false)

    //INITAL BLANK OVERLAy
    playerRef.current.overlay({
      content: '',
      class: classes.customOverlay,
      overlays: [{
        align: "center",
        content: "",
        start: 'play',
        end: 'ended'
      }]
    });

    // GUEST PLAYER
    if (!alreadyStarted) playerRef.current.play()

    // ADMIN VIEWER
    // if (alreadyStarted) {
    //   playerRef.current.currentTime((Date.now() - sessionStartTime) / 1000)
    // }

  }, [alreadyStarted, fullscreen, classes.customOverlay, videoRef])

  useEffect(() => {
    if (playerLoaded && videoStreamData) {
      videoStreamData(drmTypeRef.current).then(({ url, drmToken }) => {
        afterLoaded(url, drmTypeRef.current, drmToken)
      }).catch((err) => {
        console.log('ERROR FETCHING STREAM DATA: ', err)
      })
    }
  }, [videoStreamData, playerLoaded, afterLoaded])

  return (
    (<Root aspectRatio={aspectRatio} wmOpacity={wmOpacity}>
      {resuming && <div className={classes.resume}>
        <div className={classes.continueBack}></div>
        <Card className={classes.continue} elevation={12}>
          {videoLoaded ? <>
            {videoTime && unloadTime ? t('click_to_resume') : t('click_to_join')}
            <Button className={classes.margin} disabled={!videoLoaded} color='primary' variant='contained' onClick={handleResume}>{videoTime && unloadTime ? t('resume') : t('join')}</Button>
          </> : <>
            {t('please_wait')}
            <CircularProgress className={classes.margin} />
          </>}
        </Card>
      </div>}
      <div data-vjs-player className={classes.video}>
        <video ref={videoNodeRef} className='video-js'></video>
      </div>
      {(allowFullscreen && !isFullscreen) && <div style={{ position: 'absolute', maxHeight: '100vw', height: aspectRatio ? `calc(${100 * 1 / aspectRatio}%)` : '100vw', maxWidth: '100vh', width: aspectRatio ? `calc(${100 * aspectRatio}%)` : '100vh', transform: hasOnScreenDial ? 'translate(0,50%) rotate(270deg) translate(50%,0)' : 'rotate(270deg)' }}>
        <IconButton className={classes.fullscreenButton} color="inherit" onClick={toggleFullScreen}>
          {/* {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />} */}
          <FullscreenIcon />
        </IconButton>
      </div>}
      {/* <ReactPlayerLoader attrs={{ style }} options={{ fluid, fill }} accountId='6121265898001' playerId={playerId} videoId={videoId} onSuccess={afterLoaded} /> */}
    </Root>)
  );
})

export default TicktBoxVideoPlayer;
