import { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import * as AWS from 'aws-sdk/global';
import * as Chime from 'aws-sdk/clients/chime';
import * as S3 from 'aws-sdk/clients/s3';
import {
    ConsoleLogger,
    DefaultMessagingSession,
    LogLevel,
    MessagingSessionConfiguration,
} from 'amazon-chime-sdk-js';

const short = require('short-uuid');
const logger = new ConsoleLogger('SDK', LogLevel.INFO);

const configureChimeMessaging = async ({ credentials, chimeUserArn }, attachmentLocation) => {
    const chime = new Chime({
        region: 'us-east-1',
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        sessionToken: credentials.SessionToken
    });
    const s3 = new S3({
        region: attachmentLocation.region,
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        sessionToken: credentials.SessionToken
    });
    const endpoint = await chime.getMessagingSessionEndpoint().promise();
    const configuration = new MessagingSessionConfiguration(chimeUserArn, null, endpoint.Endpoint.Url, chime, AWS);
    const messagingSession = new DefaultMessagingSession(configuration, logger);
    return {
        chime,
        s3,
        messagingSession
    }
}

const fileToArrayBuffer = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            resolve(evt.target.result);
        };
        reader.readAsArrayBuffer(file);
    })
}

export const useChimeMessaging = (credentials, instanceId, attachmentLocation, visible = true) => {

    const [started, setStarted] = useState(false)
    const [connected, setConnected] = useState(false)
    const [chimeCredentials, setChimeCredentials] = useState()
    const [channelList, setChannelList] = useState()
    const [currentChannel, setCurrentChannel] = useState()
    const [channelMessages, setChannelMessages] = useState()
    const [loadingMessages, setLoadingMessages] = useState(true)
    const [newChannels, setNewChannels] = useState([])
    const [unreadChannels, setUnreadChannels] = useState([])
    const [attachingFile, setAttachingFile] = useState(false)
    const [receivedNewMessage, setReceivedNewMessage] = useState(false);
    const [reactionCounts, setReactionCounts] = useState({});
    const [reactionMap, setReactionMap] = useState({});
    // const [downloadingAttachment, setDownloadingAttachment] = useState({})

    const chimeRef = useRef(new Chime({ region: 'us-east-1' }))
    const s3Ref = useRef(new S3({ region: 'us-west-1' }))
    const messagingSessionRef = useRef()
    const messageLoadingToken = useRef()
    const chimeUserRef = useRef()
    const currentChannelArn = useRef()
    const restrictedChannelsRef = useRef({})
    const isVisible = useRef(visible)

    useEffect(() => {
        chimeUserRef.current = chimeCredentials
    }, [chimeCredentials])

    useEffect(() => {
        isVisible.current = visible
        // if (visible && currentChannelArn.current) setUnreadChannels(prev => prev.includes(currentChannelArn.current) ? prev.filter(x => x !== currentChannelArn.current) : prev);
        if (visible && currentChannelArn.current) setUnreadChannels(prev => prev.filter(x => x !== currentChannelArn.current));
    }, [visible])

    useEffect(() => {
        if (channelList) {
            restrictedChannelsRef.current = channelList.filter(x => x.restricted).map(x => x.ChannelArn)
        }
    }, [channelList])

    const base = useMemo(() => {
        return chimeCredentials?.chimeUserArn && instanceId ? chimeCredentials.chimeUserArn.split(`/${instanceId}`)[0] : ''
    }, [chimeCredentials, instanceId])

    const currentUser = useMemo(() => {
        const splitUserArn = chimeCredentials?.chimeUserArn.split(`/${instanceId}/user/`)
        const userId = splitUserArn && splitUserArn[splitUserArn.length - 1]
        return {
            arn: chimeCredentials?.chimeUserArn,
            isAdmin: chimeCredentials?.chimeAdmin,
            userId
        }
    }, [chimeCredentials, instanceId])

    const updateReactionCounts = useCallback(({ targetMessageId, reactedBy, reaction, removed }, msgId, senderArn) => {
        if (reaction && chimeCredentials?.chimeUserArn === senderArn) {
            setReactionMap((prevMap) => ({
                ...prevMap,
                [targetMessageId]: { messageId: msgId, reaction, ...(removed !== undefined && { removed }) }
            }));
        }

        setReactionCounts((prevCounts) => {
            const messageReactions = prevCounts[targetMessageId] || {};
            let updatedReactions = { ...messageReactions };

            // Step 1: Remove the user's previous reaction (if any)
            Object.entries(updatedReactions).forEach(([reactionType, userSet]) => {
                if (userSet.has(reactedBy)) {
                    userSet.delete(reactedBy); // Remove the user's previous reaction
                    if (userSet.size === 0) {
                        delete updatedReactions[reactionType]; // Remove empty reaction type
                    } else {
                        updatedReactions[reactionType] = new Set(userSet); // Update if not empty
                    }
                }
            });

            // Step 2: Add the new reaction if not removed
            if (!removed) {
                const updatedReactionSet = updatedReactions[reaction] || new Set();
                updatedReactionSet.add(reactedBy);
                updatedReactions[reaction] = updatedReactionSet;
            }

            // Return the updated counts, ensuring any empty entries are cleaned up
            return {
                ...prevCounts,
                [targetMessageId]: Object.keys(updatedReactions).length > 0 ? updatedReactions : undefined,
            };
        });
    }, [chimeCredentials]);


    const processMessage = useCallback(async (message) => {
        const record = JSON.parse(message?.payload);
        // console.log('Incoming Message', message.type, record, currentChannelArn.current, chimeUserRef.current);
        switch (message.type) {
            // Channel Messages
            case 'CREATE_CHANNEL_MESSAGE':
            case 'UPDATE_CHANNEL_MESSAGE':
                if ((currentChannelArn.current !== record?.ChannelArn || !isVisible.current) && !(chimeUserRef.current?.chimeAdmin && restrictedChannelsRef.current.includes(record?.ChannelArn))) {
                    // setUnreadChannels(prev => prev.includes(record.ChannelArn) ? prev : [...prev, record.ChannelArn]);
                    setReceivedNewMessage(true);
                    setUnreadChannels(prev => [...prev, record.ChannelArn])
                }
                if (record.Metadata) {
                    let metadataObj = JSON.parse(record.Metadata);
                    updateReactionCounts(metadataObj, record.MessageId, record.Sender.Arn);
                }
            case 'REDACT_CHANNEL_MESSAGE':
            case 'DELETE_CHANNEL_MESSAGE':
                // Process ChannelMessage
                if (currentChannelArn.current === record?.ChannelArn && !(chimeUserRef.current?.chimeAdmin && restrictedChannelsRef.current.includes(record?.ChannelArn))) {

                    setChannelMessages((prev) => {
                        const newMessages = [...(prev || [])]
                        const dupeIndex = newMessages.findIndex(x => x.MessageId === record?.MessageId)
                        if (dupeIndex !== -1) {
                            // console.log('replacing duplicate message')
                            newMessages[dupeIndex] = record
                        } else {
                            newMessages.push(record)
                        }
                        return newMessages
                    })

                }
                break;
            // Channels actions
            // case 'CREATE_CHANNEL':
            case 'UPDATE_CHANNEL':
                setChannelList(prev => {
                    const updatedChannelList = prev.map((c) => {
                        return c.ChannelArn === record.ChannelArn ? {
                            ...c,
                            ...record,
                            ...(JSON.parse(record.Metadata) || {}),
                        } : c
                    });
                    return updatedChannelList
                })
                break;
            case 'DELETE_CHANNEL':
                setChannelList(prev => prev.filter(x => x.ChannelArn !== record.ChannelArn))
                break;
            // // Channel Memberships
            case 'CREATE_CHANNEL_MEMBERSHIP':
                if (record?.Member.Arn === chimeUserRef.current?.chimeUserArn && record?.InvitedBy.Arn !== chimeUserRef.current?.chimeUserArn) {
                    const channelData = await chimeRef.current.describeChannel({
                        ChannelArn: record.ChannelArn,
                        ChimeBearer: chimeUserRef.current.chimeUserArn
                    }).promise()
                    const newChannelData = {
                        id: channelData.Channel.ChannelArn.replace(/(.*\/channel\/)/i, ''),
                        ...(JSON.parse(channelData.Channel.Metadata) || {}),
                        ...channelData.Channel
                    }
                    setChannelList(prev => [...prev, newChannelData])
                    setNewChannels(prev => [...prev, newChannelData.ChannelArn])
                }
                break;
            // case 'UPDATE_CHANNEL_MEMBERSHIP':
            //     if (
            //         `${appConfig.appInstanceArn}/user/${member.userId}` !==
            //         record?.InvitedBy.Arn
            //     ) {
            //         const channel = await describeChannel(
            //             record?.ChannelArn,
            //             member.userId
            //         );
            //         const newChannelList = mergeArrayOfObjects(
            //             [channel],
            //             channelListRef.current,
            //             'ChannelArn'
            //         );
            //         setChannelList(newChannelList);
            //     }
            //     break;
            case 'DELETE_CHANNEL_MEMBERSHIP':
                if (record?.Member?.Arn === chimeUserRef.current?.chimeUserArn) {
                    setChannelList(prev => prev.filter(x => x.ChannelArn !== record.ChannelArn))
                }
                break;
            default:
                console.log(`Cannot Process Message: ${JSON.stringify(message)}`);
        }
    }, [updateReactionCounts]);

    const onMessagingSessionStart = useCallback(() => {
        console.log('Chime session started')
        setStarted(true)
        setConnected(true)
    }, [])

    const onMessagingSessionConnecting = useCallback((reconnecting) => {
        if (reconnecting) {
            console.log('Chime reconnecting');
        } else {
            console.log('Chime connecting');
        }
        setConnected(false)
    }, [])

    const onMessagingSessionStop = useCallback((event) => {
        console.log(`Chime Connection Closed: ${event.code} ${event.reason}`);
        setConnected(false)
    }, [])

    const observer = useMemo(() => ({
        messagingSessionDidStart: onMessagingSessionStart,
        messagingSessionDidStartConnecting: onMessagingSessionConnecting,
        messagingSessionDidStop: onMessagingSessionStop,
        messagingSessionDidReceiveMessage: processMessage
    }), [onMessagingSessionStart, onMessagingSessionConnecting, onMessagingSessionStop, processMessage]);

    useEffect(() => {
        Promise.resolve(typeof credentials === "function" ? credentials(instanceId) : credentials).then(setChimeCredentials)
    }, [credentials, instanceId])


    useEffect(() => {
        if (chimeCredentials && attachmentLocation) {
            configureChimeMessaging(chimeCredentials, attachmentLocation).then(({ chime, s3, messagingSession }) => {
                chimeRef.current = chime
                s3Ref.current = s3
                messagingSessionRef.current = messagingSession
                messagingSessionRef.current.addObserver(observer);
                messagingSessionRef.current.start();
            })
        }
        return () => {
            if (messagingSessionRef.current) {
                messagingSessionRef.current.removeObserver(observer);
                messagingSessionRef.current.stop();
            }
        }
    }, [chimeCredentials, attachmentLocation, observer])

    const loadUserChannels = useCallback(async () => {
        if (chimeUserRef.current?.chimeUserArn) {
            const userChannels = await chimeRef.current.listChannelMembershipsForAppInstanceUser({
                ChimeBearer: chimeUserRef.current.chimeUserArn
            }).promise().catch(() => ({ ChannelMemberships: [] }));
            const channels = userChannels.ChannelMemberships.map(x => ({
                id: x.ChannelSummary.ChannelArn.replace(/(.*\/channel\/)/i, ''),
                ...(JSON.parse(x.ChannelSummary.Metadata) || {}),
                ...x.ChannelSummary
            }))
            setChannelList(channels)
        } else {
            setChannelList([])
        }
    }, [setChannelList])

    const getCurrentChannelUsers = useCallback(async () => {
        if (chimeUserRef.current?.chimeUserArn) {
            const getUsers = async (users = [], nextToken = null) => {
                const channelMembers = await chimeRef.current.listChannelMemberships({
                    ChannelArn: currentChannelArn.current,
                    Type: "DEFAULT",
                    NextToken: nextToken,
                    ChimeBearer: chimeUserRef.current.chimeUserArn
                }).promise();
                users.push(...channelMembers.ChannelMemberships.map(x => {
                    const splitUserArn = x.Member.Arn.split(`/${instanceId}/user/`)
                    const userId = splitUserArn[splitUserArn.length - 1]
                    return {
                        userId,
                        ...x.Member,
                        // ...JSON.parse(x.Metadata) || {},
                    }
                }))
                if (channelMembers.NextToken) {
                    return getUsers(users, channelMembers.NextToken)
                } else {
                    return users
                }
            }
            const channelUsers = await getUsers()
            console.log('CHANNEL USERS', channelUsers)
            return channelUsers
        } else {
            return []
        }
    }, [instanceId])

    const adminJoinChannel = useCallback(async (channelArn) => {
        if (chimeUserRef.current?.chimeUserArn) {
            await chimeRef.current.createChannelMembership({
                ChannelArn: channelArn,
                MemberArn: chimeUserRef.current.chimeUserArn,
                Type: 'DEFAULT',
                ChimeBearer: chimeUserRef.current.chimeUserArn
            }).promise();
        }
    }, [])

    const adminJoinChannelById = useCallback(async (channelId) => {
        await adminJoinChannel(`${base}/${instanceId}/channel/${channelId}`)
    }, [base, instanceId, adminJoinChannel])

    const addUserToChannel = useCallback(async (userId, channelArn) => {
        if (chimeUserRef.current?.chimeUserArn) {
            const userArn = `${base}/${instanceId}/user/${userId}`
            await chimeRef.current.createChannelMembership({
                ChannelArn: channelArn,
                MemberArn: userArn,
                Type: 'DEFAULT',
                ChimeBearer: chimeUserRef.current.chimeUserArn
            }).promise();
        }
    }, [base, instanceId])

    const addUserToChannelById = useCallback(async (userId, channelId) => {
        await addUserToChannel(userId, `${base}/${instanceId}/channel/${channelId}`)
    }, [base, instanceId, addUserToChannel])

    const removeUserFromChannel = useCallback(async (userId, channelArn) => {
        if (chimeUserRef.current?.chimeUserArn) {
            const userArn = `${base}/${instanceId}/user/${userId}`
            await chimeRef.current.deleteChannelMembership({
                ChannelArn: channelArn,
                MemberArn: userArn,
                ChimeBearer: chimeUserRef.current.chimeUserArn
            }).promise();
        }
    }, [base, instanceId])

    const removeUserFromChannelById = useCallback(async (userId, channelId) => {
        await removeUserFromChannel(userId, `${base}/${instanceId}/channel/${channelId}`)
    }, [base, instanceId, removeUserFromChannel])

    const updateChannelUsers = useCallback(async (channelArn, addedUserIds, removedUserIds) => {
        const addPromises = addedUserIds.map(userId => addUserToChannel(userId, channelArn))
        const removePromises = removedUserIds.map(userId => removeUserFromChannel(userId, channelArn))
        await Promise.all([...removePromises, ...addPromises])
    }, [addUserToChannel, removeUserFromChannel])

    const listChannelMessages = useCallback(async (channelData) => {
        if (chimeCredentials && !(chimeCredentials.chimeAdmin && channelData.restricted)) {
            const channelMessages = await chimeRef.current.listChannelMessages({
                ChannelArn: channelData.ChannelArn,
                NextToken: messageLoadingToken.current,
                ChimeBearer: chimeCredentials.chimeUserArn
            }).promise();
            channelMessages.ChannelMessages.map((message) => {
                if (message.Metadata) {
                    const metadata = JSON.parse(message.Metadata);
                    if (metadata.reaction) {
                        updateReactionCounts(metadata, message.MessageId, message.Sender.Arn);
                        return false;
                    } else return true
                }
            });
            messageLoadingToken.current = channelMessages.NextToken
            return channelMessages.ChannelMessages
        }
    }, [chimeCredentials, updateReactionCounts])

    useEffect(() => {
        if (currentChannel) {
            setChannelMessages()
            messageLoadingToken.current = null
            currentChannelArn.current = currentChannel.ChannelArn
            setNewChannels(prev => prev.includes(currentChannel.ChannelArn) ? prev.filter(x => x !== currentChannel.ChannelArn) : prev)
            // if (isVisible.current) setUnreadChannels(prev => prev.includes(currentChannel.ChannelArn) ? prev.filter(x => x !== currentChannel.ChannelArn) : prev);
            if (isVisible.current) setUnreadChannels(prev => prev.filter(x => x !== currentChannel.ChannelArn));
            listChannelMessages(currentChannel).then((messages) => {
                messages.sort((a, b) => a.CreatedTimestamp < b.CreatedTimestamp ? -1 : a.CreatedTimestamp > b.CreatedTimestamp ? 1 : 0);
                setChannelMessages(messages)
                setLoadingMessages(false)
            }).catch(() => setLoadingMessages(false))
        }
    }, [currentChannel, listChannelMessages])

    const loadChannelMessages = useCallback(async () => {
        setLoadingMessages(true)
        if (messageLoadingToken.current) {
            const messages = await listChannelMessages(currentChannel)
            messages.sort((a, b) => a.CreatedTimestamp < b.CreatedTimestamp ? -1 : a.CreatedTimestamp > b.CreatedTimestamp ? 1 : 0);
            setChannelMessages(prev => [...messages, ...prev])
        }
        setLoadingMessages(false)
    }, [currentChannel, listChannelMessages])

    const sendChannelMessage = useCallback(async (messageContent, attachment, metadata) => {
        if (chimeUserRef.current?.chimeUserArn) {
            let msgContent = messageContent;
            if (attachment && attachmentLocation) {
                setAttachingFile(true)
                const fileBuffer = await fileToArrayBuffer(attachment)
                const splitUserArn = chimeUserRef.current?.chimeUserArn.split(`/${instanceId}/user/`)
                const userId = splitUserArn[splitUserArn.length - 1]
                const fileKey = `${instanceId}/${userId}/${short.generate()}_${attachment.name.replace(/ /g, '_')}`
                try {
                    await s3Ref.current.putObject({
                        Body: fileBuffer,
                        ContentType: attachment.type,
                        Bucket: attachmentLocation.bucket,
                        Key: fileKey,
                        ServerSideEncryption: "AES256",
                        // Tagging: "key1=value1&key2=value2"
                    }).promise()
                    const attachmentMetadata = {
                        attachments: [
                            {
                                fileKey,
                                name: attachment.name,
                                size: attachment.size,
                                type: attachment.type,
                            },
                        ],
                    }
                    metadata = { ...metadata, ...attachmentMetadata }
                } catch (er) {

                }
                msgContent += "\n\n Attachment:"
                setAttachingFile(false)
            }
            const sender = {
                ...(metadata || {}),
                arn: chimeUserRef.current.chimeUserArn,
                username: chimeUserRef.current.username,
            }
            await chimeRef.current.sendChannelMessage({
                ChannelArn: currentChannelArn.current,
                Content: msgContent,
                Metadata: JSON.stringify(sender),
                Persistence: 'PERSISTENT',
                Type: 'STANDARD',
                ChimeBearer: chimeUserRef.current.chimeUserArn
            }).promise();
        }
    }, [instanceId, attachmentLocation])

    const sendReaction = useCallback(async (targetMessageId, reaction) => {
        if (chimeUserRef.current?.chimeUserArn) {
            const reactedBy = chimeUserRef.current.chimeUserArn.split('/').at(-1)
            const sender = {
                reaction,
                targetMessageId: targetMessageId,
                reactedBy: reactedBy,
                arn: chimeUserRef.current.chimeUserArn,
                username: chimeUserRef.current.username,
            }

            const existingReaction = reactionMap[targetMessageId];

            if (existingReaction) {
                const isReactionRemoved = existingReaction.removed;
                const isSameReaction = existingReaction.reaction === reaction;

                if (isSameReaction) {
                    if (isReactionRemoved) {
                        // Re-react if the same reaction was previously removed
                        // console.log('re-reacting with the same emoji');
                        await chimeRef.current.updateChannelMessage({
                            ChannelArn: currentChannelArn.current,
                            MessageId: existingReaction.messageId,
                            Content: `${chimeUserRef.current.username} reacted with ${reaction} to message ${targetMessageId}`,
                            Metadata: JSON.stringify({ ...sender, removed: false }),
                            ChimeBearer: chimeUserRef.current.chimeUserArn,
                        }).promise();
                    } else {
                        // Unreact if clicking the same reaction again
                        // console.log('unreacting');
                        await chimeRef.current.updateChannelMessage({
                            ChannelArn: currentChannelArn.current,
                            MessageId: existingReaction.messageId,
                            Content: `${chimeUserRef.current.username} unreacted with ${reaction} to message ${targetMessageId}`,
                            Metadata: JSON.stringify({ ...sender, removed: true }),
                            ChimeBearer: chimeUserRef.current.chimeUserArn,
                        }).promise();
                    }
                } else {
                    // Update reaction to a new emoji
                    // console.log('changing reaction to a new emoji');
                    await chimeRef.current.updateChannelMessage({
                        ChannelArn: currentChannelArn.current,
                        MessageId: existingReaction.messageId,
                        Content: `${chimeUserRef.current.username} reacted with ${reaction} to message ${targetMessageId}`,
                        Metadata: JSON.stringify({ ...sender, removed: false }),
                        ChimeBearer: chimeUserRef.current.chimeUserArn,
                    }).promise();
                }
            } else {
                // No existing reaction; send a new reaction message
                // console.log('sending new reaction');
                const result = await chimeRef.current.sendChannelMessage({
                    ChannelArn: currentChannelArn.current,
                    Content: `${chimeUserRef.current.username} reacted with ${reaction} to message ${targetMessageId}`,
                    Metadata: JSON.stringify(sender),
                    Persistence: 'PERSISTENT',
                    Type: 'STANDARD',
                    ChimeBearer: chimeUserRef.current.chimeUserArn,
                }).promise();
            }
        }
    }, [reactionMap])

    const updateChannelMessage = useCallback(async (messageId, messageContent, metadata) => {
        if (chimeUserRef.current?.chimeUserArn) {
            await chimeRef.current.updateChannelMessage({
                ChannelArn: currentChannelArn.current,
                MessageId: messageId,
                Content: messageContent,
                Metadata: metadata || undefined,
                ChimeBearer: chimeUserRef.current.chimeUserArn
            }).promise();
        }
    }, [])

    const redactChannelMessage = useCallback(async (messageId) => {
        if (chimeUserRef.current?.chimeUserArn) {
            await chimeRef.current.redactChannelMessage({
                ChannelArn: currentChannelArn.current,
                MessageId: messageId,
                ChimeBearer: chimeUserRef.current.chimeUserArn
            }).promise();
        }
    }, [])

    const downloadMessageAttachment = useCallback(async (attachmentData) => {
        if (attachmentLocation) {
            const attachment = await s3Ref.current.getObject({
                Bucket: attachmentLocation.bucket,
                Key: attachmentData.fileKey,
            }).promise()
            const buffer = Buffer.from(attachment.Body)
            const blob = new Blob([buffer], { type: attachmentData.type })
            const href = URL.createObjectURL(blob)
            Object.assign(document.createElement('a'), {
                href,
                download: attachmentData.name,
            }).click()
        }
    }, [attachmentLocation])

    const createPrivateChannel = useCallback(async (userId, username, techCheck) => {
        if (chimeUserRef.current?.chimeUserArn) {
            const splitUserArn = chimeUserRef.current?.chimeUserArn.split(`/${instanceId}/user/`)
            const currentUserId = splitUserArn[splitUserArn.length - 1]
            const currentUsername = chimeUserRef.current?.username
            const newChannel = {
                Name: `Chat ${techCheck ? '(TC) -' : '-'}`,
                Mode: 'RESTRICTED',
                Privacy: 'PRIVATE',
                Metadata: JSON.stringify({
                    type: 'user',
                    users: {
                        [userId]: username,
                        [currentUserId]: currentUsername
                    },
                    techCheck
                })
            }
            const privateChannel = await chimeRef.current.createChannel({
                AppInstanceArn: `${base}/${instanceId}`,
                ...newChannel,
                ChimeBearer: chimeUserRef.current.chimeUserArn
            }).promise();
            newChannel.ChannelArn = privateChannel.ChannelArn
            await chimeRef.current.createChannelMembership({
                ChannelArn: privateChannel.ChannelArn,
                MemberArn: `${base}/${instanceId}/user/${userId}`,
                Type: 'DEFAULT',
                ChimeBearer: chimeUserRef.current.chimeUserArn
            }).promise();
            // return newChannel
            const newChannelData = {
                id: newChannel.ChannelArn.replace(/(.*\/channel\/)/i, ''),
                ...(JSON.parse(newChannel.Metadata) || {}),
                ...newChannel
            }
            setChannelList(prev => [...prev, newChannelData])
            return newChannelData
        }
    }, [base, instanceId])

    const clearAllNotifications = useCallback(() => {
        setNewChannels([])
        setUnreadChannels([])
    }, [])

    return {
        started,
        connected,
        currentUser,
        channelList,
        currentChannel,
        loadingMessages,
        channelMessages,
        attachingFile,
        // downloadingAttachment,
        newChannels,
        unreadChannels,
        loadUserChannels,
        setCurrentChannel,
        getCurrentChannelUsers,
        // getUserChannels,
        adminJoinChannel,
        adminJoinChannelById,
        addUserToChannel,
        addUserToChannelById,
        removeUserFromChannel,
        removeUserFromChannelById,
        updateChannelUsers,
        loadChannelMessages,
        sendChannelMessage,
        sendReaction,
        reactionCounts,
        updateChannelMessage,
        redactChannelMessage,
        downloadMessageAttachment,
        createPrivateChannel,
        clearAllNotifications,
        receivedNewMessage,
        setReceivedNewMessage
    }
};