import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import queryString from 'query-string';
// import moment from 'moment'
// // import seasiLogo from './resources/seasi-logo.jpg'
// import seasiLogo from './resources/seasi-logo.png'
// import pfmLogo from './resources/pfm-logo.png'
// import vvtvLogo from './resources/vv-logo.png'
// import ewLogo from './resources/ew-logo.png'
// import background from './resources/black-bg.jpg'
import BrandedPage from './BrandedPage'
import LoadingPage from './LoadingPage';
import config from './config.json'
import SessionInfoBanner from './SessionInfoBanner';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const PREFIX = 'CompletePage';

const classes = {
    fullscreen: `${PREFIX}-fullscreen`,
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`
};

const Root = styled('div')({
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    [`& .${classes.root}`]: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    }
});

const guestCompletePromise = (guestId, sessionId, requestId) =>
    fetch(`https://${config.rest.sessionGuestAPI}/complete`, {
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ guestId, sessionId, requestId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const CompletePage = React.memo(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state, search } = location;

    const [loading, setLoading] = useState(true)
    const [reloading, setReloading] = useState(false)
    const [valid, setValid] = useState(false)
    const [techCheck, setTechCheck] = useState(false)
    const [videoTitle, setVideoTitle] = useState()
    const [sessionDate, setSessionDate] = useState()
    const [sessionTeam, setSessionTeam] = useState()
    const [bannerLabel, setBannerLabel] = useState();

    const { t } = useTranslation('self_tech_check_step_6');

    const params = queryString.parse(search);

    const { sessionid: sessionId, id: userId, requestid: requestId } = state || params //take a look later

    console.log(sessionId, userId, requestId)

    useEffect(() => {
        if (!sessionId || !userId) {
            navigate('/')
        } else {
            if ((state && state.redirect)) {
                setSessionTeam(state.team)
                setSessionDate(state.scheduledStartTime)
                setVideoTitle(state.videoTitle)
                setTechCheck(state.techCheck)
                setReloading(state.returning)
                setValid(true)
                setLoading(false)
            } else {
                guestCompletePromise(userId, sessionId, requestId).then((res) => {
                    console.log(res)
                    setSessionTeam(res.team)
                    setSessionDate(res.techCheck ? res.guestCheckInTime  : res.scheduledStartTime)
                    setBannerLabel(res.techCheck ? t('checkInTime') : undefined)
                    setVideoTitle(res.videoTitle)
                    setTechCheck(res.techCheck)
                    setReloading(res.returning)
                    setValid(true)
                    setLoading(false)
                }).catch((e) => {
                    console.log(e)
                    navigate('/')
                })
            }
        }
    }, [navigate, requestId, sessionId, state, userId])

    return (
        <Root>
            <div className={classes.root}>
                {loading && <LoadingPage />}
                {(!loading && valid) && <BrandedPage team={sessionTeam}>
                    <SessionInfoBanner videoTitle={videoTitle} sessionDate={sessionDate} dateLabel={bannerLabel} />
                    <div className={classes.container}>
                        <Typography variant="h4">{t('thanks_for_viewing')}</Typography>
                        <br />
                        <Typography variant="h6">
                            {techCheck ? t('tech_check_portion_completed') : !reloading ? t('close_tab_and_return_to_session') : t('join_us_again')}
                        </Typography>
                    </div>
                </BrandedPage>}
            </div>
        </Root>
    );
})

export default CompletePage;
