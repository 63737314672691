import React, { useState, useRef, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/material/styles';
import * as workerTimers from 'worker-timers'
import * as tf from '@tensorflow/tfjs'
import * as speechCommands from '@tensorflow-models/speech-commands'
import { Tracker, flattenQueue, getInputTensorFromFrequencyData } from '@tensorflow-models/speech-commands/dist/browser_fft_extractor'
import { normalize } from '@tensorflow-models/speech-commands/dist/browser_fft_utils'
import { useTranslation } from 'react-i18next';

const PREFIX = 'MLMic';

const classes = {
    root: `${PREFIX}-root`,
    webcam: `${PREFIX}-webcam`,
    spinnerBox: `${PREFIX}-spinnerBox`,
    spinner: `${PREFIX}-spinner`,
    label: `${PREFIX}-label`,
    canvasBox: `${PREFIX}-canvasBox`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({hideVideo}) => ({
    display: 'flex',
    justifyContent: 'center',
    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '85%',
        height: '85%'
    },
    [`& .${classes.webcam}`]: {
        display: hideVideo ? 'none' : 'block',
        height: '100%',
        width: '100%'
    },
    [`& .${classes.spinnerBox}`]: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    [`& .${classes.spinner}`]: {
        margin: '1rem'
    },
    [`& .${classes.label}`]: {
        fontSize: '1rem',
    },
    [`& .${classes.canvasBox}`]: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const sampleRate = 44100
const fftBase = 1024

const audioConstraints = {
    echoCancellation: false,
    noiseSuppression: false,
    autoGainControl: false
}

const choiceMap = { // maybe will solve the mic issue // sangat
    'one': 1,
    'two': 2,
    'three': 3,
    'four': 4,
    'five': 5,
    'six': 6,
    'seven': 7,
    'eight': 8,
    'nine': 9,
    'zero': 0
}

const numberGroup = (maxLength = 5, group = [], choices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) => {
    const i = Math.floor(Math.random() * (choices.length - 1))
    group.push({
        digit: choices[i],
        detected: false
    })
    choices.splice(i, 1)
    if (group.length === maxLength) {
        return group
    } else {
        return numberGroup(maxLength, group, choices)
    }
}

const MLMic = React.memo(({ onUserMedia, onUserMediaError, withDetection, detectionInterval, onDetection }) => {
    const [micPermissionDetected, setMicPermissionDetected] = useState(false)
    const [micError, setMicError] = useState()
    const [modelsLoaded, setModelsLodaded] = useState(false)
    const [loading, setLoading] = useState(true)

    const theme = useTheme();

    const audioContext = useRef();
    const analyserRef = useRef()
    const canvasRef = useRef()
    const ctx = useRef()
    const canvasAni = useRef()
    const meterRef = useRef()
    const meterCtx = useRef()
    const meterAni = useRef()
    const volumeCheckRef = useRef()
    const streamRef = useRef()
    const recognizerRef = useRef()
    const timerRef = useRef()
    const detectionRef = useRef(numberGroup())
    const intervalRef = useRef(detectionInterval)
    const textWidth = useRef(0)
    const wordMap = useRef([])
    const freqDataQueue = useRef([])
    const trackerRef = useRef()
    const reloadRef = useRef(false)
    const {t} = useTranslation('self_tech_check_step_3');

    // if (detections) detections.current = detectionRef.current

    useEffect(() => {
        intervalRef.current = detectionInterval
    }, [detectionInterval])

    const fillDigits = useCallback(() => {
        canvasAni.current = window.requestAnimationFrame(() => {
            ctx.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            let left = (canvasRef.current.width - textWidth.current) / 2
            for (let i = 0; i < detectionRef.current.length; ++i) {
                const num = detectionRef.current[i];
                ctx.current.fillStyle = num.detected ? theme.palette.success.main : 'black';
                ctx.current.fillText(num.digit, left, canvasRef.current.height / 2);
                left += ctx.current.measureText(num.digit).width;
            }
            canvasAni.current = null
        })
    }, [theme])

    const startMeter = useCallback(() => {
        meterCtx.current = meterRef.current.getContext("2d");
        meterRef.current.style.backgroundColor ='black'
        // ctx.current.globalAlpha = 0.3;

        volumeCheckRef.current = () => {
            const volumes = new Uint8Array(analyserRef.current.frequencyBinCount);
            analyserRef.current.getByteFrequencyData(volumes);
            let volumeSum = 0;
            for (const volume of volumes)
                volumeSum += volume * volume;
            const averageVolume = Math.sqrt(volumeSum / volumes.length);
            meterCtx.current.clearRect(0, 0, meterRef.current?.width || 300, meterRef.current?.height || 30);
            meterCtx.current.fillStyle = '#00ff00';
            meterCtx.current.fillRect(10, 10, (meterRef.current?.width || 300 - 20) * (averageVolume / 127), (meterRef.current?.height || 30 - 20)); // x,y,w,h
            meterAni.current = window.requestAnimationFrame(volumeCheckRef.current);
        }

        meterAni.current = window.requestAnimationFrame(volumeCheckRef.current);

    }, [])

    useEffect(() => {
        const checkPermissions = async () => {
            if (navigator.permissions) {
                console.log('checking permissions...')
                const micPermissions = await navigator.permissions.query({ name: 'microphone' })
                micPermissions.onchange = (event) => {
                    console.log('MIC PERMISSIONS CHANGED', event.target)
                    setMicPermissionDetected(event.target.state === 'granted')
                }
                setMicPermissionDetected(micPermissions.state === 'granted')
            }
        }
        const loadModels = async () => {
            console.log('loading models...')
            await tf.ready()
            const tfBackend = await tf.getBackend()
            recognizerRef.current = speechCommands.create('BROWSER_FFT')
            await recognizerRef.current.ensureModelLoaded();
            wordMap.current = recognizerRef.current.wordLabels()
            console.log('models loaded', tfBackend)
            setModelsLodaded(true)
        }
        const loadMic = async () => {
            streamRef.current = await new Promise((resolve, reject) => navigator.getUserMedia({ audio: audioConstraints }, resolve, reject))
            audioContext.current = new AudioContext({ sampleRate })
            analyserRef.current = audioContext.current.createAnalyser();
            const microphone = audioContext.current.createMediaStreamSource(streamRef.current);
            analyserRef.current.smoothingTimeConstant = 0.0 //0.3;
            analyserRef.current.fftSize = fftBase * 2;
            analyserRef.current.minDecibels = -127;
            analyserRef.current.maxDecibels = 0;

            microphone.connect(analyserRef.current);
        }
        const handleLoaded = () => {
            ctx.current = canvasRef.current.getContext("2d");
            ctx.current.font = '72px sans-serif';
            // ctx.current.textAlign = "center";
            ctx.current.textBaseline = "middle";
            textWidth.current = ctx.current.measureText(detectionRef.current.map(x => x.digit).join('')).width
            fillDigits()
            startMeter()
            setLoading(false)
            onUserMedia()
        }
        const handleError = (error) => {
            console.log('MIC ERROR', error)
            setMicError(error)
            onUserMediaError(error, () => {
                setMicError()
            })
        }
        checkPermissions().then(loadModels).then(loadMic).then(handleLoaded).catch(handleError)
        return () => {
            if (timerRef.current) workerTimers.clearInterval(timerRef.current)
            if (canvasAni.current) window.cancelAnimationFrame(canvasAni.current)
            if (meterAni.current) window.cancelAnimationFrame(meterAni.current)
            if (streamRef.current) streamRef.current.getAudioTracks()[0].stop()

        }
    }, [onUserMedia, onUserMediaError, fillDigits, startMeter])

    const handleMicCheck = useCallback(() => {

        const overlapFactor = 0.75
        const [batchDim, audioFrames, freqDataPoints] = recognizerRef.current.modelInputShape()
        const frameDurationMs = fftBase / sampleRate * 1e3
        const detectionPeriod = Math.max(1, Math.round(audioFrames * (1 - overlapFactor)));

        ctx.current = canvasRef.current.getContext("2d");

        freqDataQueue.current = [];
        trackerRef.current = new Tracker(detectionPeriod)

        const detectWords = () => {
            const frequencies = new Float32Array(fftBase);
            analyserRef.current.getFloatFrequencyData(frequencies);

            if (frequencies[0] === -Infinity) {
                return;
            }

            freqDataQueue.current.push(frequencies.slice(0, freqDataPoints));

            if (freqDataQueue.current.length > audioFrames) {
                // Drop the oldest frame (least recent).
                freqDataQueue.current.shift();
            }

            const shouldFire = trackerRef.current.tick();
            if (shouldFire) {
                const freqData = flattenQueue(freqDataQueue.current);
                const freqDataTensor = normalize(getInputTensorFromFrequencyData(freqData, [1, audioFrames, freqDataPoints, 1]));

                recognizerRef.current.recognize(freqDataTensor).then((result) => {
                    const detection = wordMap.current.reduce((p, word, i) => {
                        if (Object.keys(choiceMap).includes(word) && result.scores[i] >= 0.99) return {
                            word,
                            digit: choiceMap[word],
                            score: result.scores[i]
                        }
                        return p
                    }, null)
                    if (detection) {
                        console.log('DETECTION RESULT: ', detection)
                        const first = detectionRef.current.find(x => !x.detected)
                        if (first?.digit === detection.digit) first.detected = true
                        if (!detectionRef.current.find(x => !x.detected)) onDetection()
                    }
                    fillDigits()
                    tf.dispose([freqDataTensor, result])
                })

            }
        }

        timerRef.current = workerTimers.setInterval(detectWords, frameDurationMs)

    }, [onDetection, fillDigits])

    useEffect(() => {
        if (modelsLoaded && !loading) {
            if (withDetection) {
                if (reloadRef.current) {
                    detectionRef.current = numberGroup()
                    textWidth.current = ctx.current.measureText(detectionRef.current.map(x => x.digit).join('')).width
                    fillDigits()
                }
                handleMicCheck()
                reloadRef.current = true
                // timerRef.current = workerTimers.setInterval(webcamCheck, intervalRef.current)
            } else if (timerRef.current) {
                workerTimers.clearInterval(timerRef.current)
                timerRef.current = null
            }
        }
    }, [withDetection, loading, modelsLoaded, fillDigits, handleMicCheck])

    return (
        (<Root>
            {(loading && !micError) && <div className={classes.spinnerBox}>
                <CircularProgress className={classes.spinner} />
                {!micPermissionDetected && <Typography>
                    {t('grant_mic')}
                </Typography>}
            </div>}
            {(modelsLoaded && !micError) && <div className={classes.canvasBox}>
                {/* <canvas ref={canvasRef} width="320px" height="30px" style={{ backgroundColor: 'black' }} /> */}
                <canvas ref={canvasRef} width="300px" height="100px" />
                <canvas ref={meterRef} width="300px" height="30px" />
            </div>}
        </Root>)
    );
})

export default MLMic;