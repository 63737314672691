import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { CircularProgress, Typography, Button, IconButton, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import { makeStyles } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Webcam from 'react-webcam';
import * as workerTimers from 'worker-timers'
import * as faceapi from '@vladmandic/face-api';
import tfManifest from '@vladmandic/face-api/model/tiny_face_detector_model-weights_manifest.json'
import tfModel from '@vladmandic/face-api/model/tiny_face_detector_model.bin'
import frManifest from '@vladmandic/face-api/model/face_recognition_model-weights_manifest.json'
import frModel from '@vladmandic/face-api/model/face_recognition_model.bin'
import agManifest from '@vladmandic/face-api/model/age_gender_model-weights_manifest.json'
import agModel from '@vladmandic/face-api/model/age_gender_model.bin';
import { useTranslation } from 'react-i18next';

const PREFIX = 'MLWebcam';

const classes = {
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`,
    webcam: `${PREFIX}-webcam`,
    webcamBox: `${PREFIX}-webcamBox`,
    overlayBox: `${PREFIX}-overlayBox`,
    switchButton: `${PREFIX}-switchButton`,
    dialog: `${PREFIX}-dialog`,
    dialogContent: `${PREFIX}-dialogContent`,
    spinnerBox: `${PREFIX}-spinnerBox`,
    spinner: `${PREFIX}-spinner`,
    label: `${PREFIX}-label`,
    selectBox: `${PREFIX}-selectBox`,
    photoButton: `${PREFIX}-photoButton`,
    loadingMessage: `${PREFIX}-loadingMessage`,
    loadingEllipsis: `${PREFIX}-loadingEllipsis`,
}

const Root = styled('div')(({hidden}) => ({
    display: 'flex',
    justifyContent: 'center',
    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '85%',
        height: '85%'
    },
    '@keyframes ellipsis': {
        'to': {
            width: '1.25em'
        }
    },
    [`& .${classes.container}`]: {
        display: hidden ? 'none' : 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1
    },
    [`& .${classes.webcam}`]: {
        height: '100%',
        width: '100%'
    },
    [`& .${classes.webcamBox}`]: {
        height: '100%',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    [`& .${classes.overlayBox}`]: {
        height: '100%',
        width: '100%',
        position: 'absolute'
    },
    [`& .${classes.switchButton}`]: {
        color: 'white',
        zIndex: 2,
        opacity: 0.25,
        '&:hover': {
            opacity: 1
        },
        mixBlendMode: 'difference'
    },
    [`& .${classes.dialog}`]: {
        minWidth: '250px'
    },
    [`& .${classes.dialogContent}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    [`& .${classes.spinnerBox}`]: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    [`& .${classes.spinner}`]: {
        margin: '1rem'
    },
    [`& .${classes.label}`]: {
        fontSize: '1rem',
    },
    [`& .${classes.selectBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    [`& .${classes.photoButton}`]: {
        margin: '12px'
    },
    [`& .${classes.loadingMessage}`]: {
        display: 'inline-flex'
    },
    [`& .${classes.loadingEllipsis}`]: {
        minWidth: '1.25em',
        '&::after': {
            overflow: 'hidden',
            display: 'inline-block',
            verticalAlign: 'bottom',
            animation: '$ellipsis steps(4, end) 900ms infinite',
            content: '"\\2026"', /* ascii code for the ellipsis character */
            width: '0px'
        }
    }
}))

const videoConstraints = {
    width: 1280,
    height: 720,
    // facingMode: "user"
}

const audioConstraints = {
    echoCancellation: false,
    noiseSuppression: false,
    autoGainControl: false
}


const getModelWeightMap = async (manifest, model) => {
    const splitPath = model.split(/\/(?=[^/]+$)/g)
    manifest[0].paths = [splitPath[1]]
    console.log(manifest, model)
    const getModel = () => fetch(model).then(res => res.arrayBuffer()).then(data => [data])
    // const getModels = () => fetch(model).then(res => res.arrayBuffer()).then(data => (allModels.push(data), allModels))
    const loadWeights = faceapi.tf.io.weightsLoaderFactory(getModel);
    const weights = await loadWeights(manifest, splitPath[0])
    return weights
}

function EventSet() {
    const event = new EventTarget()
    const handler = {
        get(target, prop, receiver) {
            const obj = prop in target ? target : event
            const val = Object(obj)[prop];
            // return typeof val === "function" ? val.bind(obj) : val;
            return typeof val === "function" ? new Proxy(val.bind(obj), {
                apply(fn, that, args) {
                    if (prop in target) event.dispatchEvent(new CustomEvent(prop, {
                        detail: {
                            args
                        }
                    }))
                    return fn.apply(receiver === fn ? obj : that, args)
                }
            }) : val;
        }
    }
    return new Proxy(new Set(), handler)
}

const MLWebcam = React.memo(({ withAudio, withDetection, withPhoto, imgFormat = "image/png", disablePhotoCapture, detectionInterval = 200, detections, onUserMedia, onUserMediaError, onPhotoCapture, hidden }) => {
    const [availableDevices, setAvailableDevices] = useState()
    const [selectedCamera, setSelectedCamera] = useState()
    const [defaultCamera, setDefaultCamera] = useState()
    const [cameraPermissionDetected, setCameraPermissionDetected] = useState(false)
    const [micPermissionDetected, setMicPermissionDetected] = useState(false)
    const [webcamError, setWebcamError] = useState()
    const [modelsLoaded, setModelsLodaded] = useState(false)
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)

    const webcamRef = useRef()
    const timerRef = useRef()
    const isDetected = useRef(false)
    const detectionRef = useRef(new EventSet())
    const intervalRef = useRef(detectionInterval)
    const onError = useRef(onUserMediaError)
    const selectCamRef = useRef(null);
    const { t } = useTranslation('self_tech_check_step_3');

    if (detections) detections.current = detectionRef.current

    const cameraConstraints = useMemo(() => {
        return {
            ...videoConstraints,
            deviceId: selectedCamera
        }
    }, [selectedCamera])

    useEffect(() => {
        intervalRef.current = detectionInterval
    }, [detectionInterval])

    useEffect(() => {
        onError.current = onUserMediaError
    }, [onUserMediaError])

    useEffect(() => {
        const checkPermissions = async () => {
            if (navigator.permissions) {
                console.log('checking permissions...')
                const cameraPermissions = await navigator.permissions.query({ name: 'camera' })
                const micPermissions = await navigator.permissions.query({ name: 'microphone' })
                console.log(cameraPermissions, micPermissions)
                cameraPermissions.onchange = (event) => {
                    console.log('CAMERA PERMISSIONS CHANGED', event.target)
                    setCameraPermissionDetected(event.target.state === 'granted')
                }
                micPermissions.onchange = (event) => {
                    console.log('MIC PERMISSIONS CHANGED', event.target)
                    setMicPermissionDetected(event.target.state === 'granted')
                }
                setCameraPermissionDetected(cameraPermissions.state === 'granted')
                setMicPermissionDetected(micPermissions.state === 'granted')
            }
        }
        const loadModels = async () => {
            console.log('loading models...')
            const tfWeightMap = await getModelWeightMap(tfManifest, tfModel)
            const frWeightMap = await getModelWeightMap(frManifest, frModel)
            const agWeightMap = await getModelWeightMap(agManifest, agModel)
            await Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromWeightMap(tfWeightMap),
                faceapi.nets.faceRecognitionNet.loadFromWeightMap(frWeightMap),
                faceapi.nets.ageGenderNet.loadFromWeightMap(agWeightMap)
            ])
            console.log('models loaded')
            setModelsLodaded(true)
        }
        checkPermissions().then(loadModels).catch((error) => {
            console.log('WEBCAM ERROR', error)
            setWebcamError(error)
            if (onError.current) onError.current(error, () => {
                setWebcamError()
            })
        })
        return () => {
            if (timerRef.current) workerTimers.clearInterval(timerRef.current)
            if (webcamRef.current) {
                webcamRef.current.video.src = ""
                webcamRef.current.stream.getVideoTracks()[0].stop()
            }
        }
    }, [])

    useEffect(() => {
        if (modelsLoaded && !loading) {
            if (withDetection) {
                console.log(webcamRef.current)
                const opts = new faceapi.TinyFaceDetectorOptions()
                const webcamCheck = async () => {
                    console.log('detecting face...')
                    const detection = await faceapi.detectSingleFace(webcamRef.current.video, opts).withAgeAndGender()
                    if (detection) {
                        detectionRef.current.add(detection)
                        if (!isDetected.current) {
                            isDetected.current = true
                            detectionRef.current.dispatchEvent(new CustomEvent('detection_change', { detail: { detected: true } }))
                        }
                    } else if (isDetected.current) {
                        isDetected.current = false
                        detectionRef.current.dispatchEvent(new CustomEvent('detection_change', { detail: { detected: false } }))
                    }
                }
                timerRef.current = workerTimers.setInterval(webcamCheck, intervalRef.current)
            } else if (timerRef.current) {
                workerTimers.clearInterval(timerRef.current)
                timerRef.current = null
            }
        }
    }, [withDetection, loading, modelsLoaded])

    const handleLoaded = useCallback(() => {
        const checkDevices = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices()
            const cameras = devices.filter(({ kind }) => kind === "videoinput")
            console.log('CAMERAS: ', cameras)
            if (cameras.length) {
                if (cameras.length > 1) {
                    console.log("multiple cameras detected")
                    setAvailableDevices(cameras)
                }
            }
        }
        checkDevices().then(() => {
            setDefaultCamera(webcamRef.current.stream.getVideoTracks()[0].getSettings().deviceId)
            setLoading(false)
            onUserMedia()
        })
    }, [onUserMedia])


    const handleError = useCallback((error) => {
        console.log('WEBCAM ERROR', error)
        setWebcamError(error)
        onUserMediaError(error, () => {
            setWebcamError()
        })
    }, [onUserMediaError])

    const capturePhoto = useCallback(() => {
        const imgData = webcamRef.current.getScreenshot()
        if (onPhotoCapture) onPhotoCapture(imgData)
    }, [onPhotoCapture])

    const openDialog = useCallback(() => {
        setOpen(true)
    }, [])

    const closeDialog = useCallback(() => {
        setOpen(false)
    }, [])

    const selectCamera = useCallback((event) => {
        console.log('SELECTED NEW CAMERA', event.target.value)
        selectCamRef.current.node.blur()
        setSelectedCamera(event.target.value);
        setOpen(false)
    }, [])

    return (<Root hidden={hidden}>
        {(loading && !webcamError) && <div className={classes.spinnerBox}>
            <CircularProgress className={classes.spinner} />
            <Typography className={classes.loadingMessage}>
                {t('loading_text')}<span className={classes.loadingEllipsis} />
            </Typography>
            {!(cameraPermissionDetected && (!withAudio || micPermissionDetected)) && <Typography>
                {withAudio ? t('grant_camera_and_mic') : t('grant_camera')}.
            </Typography>}
        </div>}
        {(modelsLoaded && !webcamError) && <div className={classes.container}>
            <div className={classes.webcamBox}>
                <Webcam
                    className={classes.webcam}
                    audio={withAudio}
                    height={720}
                    ref={webcamRef}
                    screenshotFormat={imgFormat}
                    width={1280}
                    videoConstraints={cameraConstraints}
                    audioConstraints={audioConstraints}
                    onUserMedia={handleLoaded}
                    onUserMediaError={handleError}
                />
                {!loading && availableDevices && <div className={classes.selectBox}>
                    <Typography variant="overline">,{t('choose_camera')}</Typography>
                    <Select
                        inputRef={selectCamRef}
                        value={selectedCamera || defaultCamera}
                        onChange={selectCamera}
                    // displayEmpty
                    // className={classes.selectField}
                    >
                        {availableDevices.map(camera => <MenuItem key={`camera${camera.deviceId}`} value={camera.deviceId}>{camera.label}</MenuItem>)}
                    </Select>
                </div>}
                {/* {!loading && availableDevices && <div className={classes.overlayBox}>
                    <IconButton className={classes.switchButton} color="inherit" disabled={withDetection} onClick={openDialog}>
                        <SwitchCameraIcon />
                    </IconButton>
                    <Dialog className={classes.dialog} open={open}>
                        <DialogTitle>Select Webcam</DialogTitle>
                        <DialogContent>
                            <div className={classes.dialogContent}>
                                <Select
                                    value={selectedCamera || defaultCamera}
                                    onChange={selectCamera}
                                    // displayEmpty
                                    className={classes.selectField}
                                >
                                    {availableDevices.map(camera => <MenuItem key={`camera${camera.deviceId}`} value={camera.deviceId}>{camera.label}</MenuItem>)}
                                </Select>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button color='primary' variant='contained' size="small" onClick={closeDialog}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>} */}
            </div>
            {(!loading && withPhoto) && <Button
                color="primary"
                variant="outlined"
                className={classes.photoButton}
                onClick={capturePhoto}
                disabled={disablePhotoCapture}
            >
                {t('capture_photo')}
            </Button>}
        </div>}
    </Root>)
})

export default MLWebcam;