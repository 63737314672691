import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import moment from 'moment'
import seasiLogo from './resources/seasi-logo.png'
import pfmLogo from './resources/pfm-logo.png'
import vvtvLogo from './resources/vv-logo.png'
import ewLogo from './resources/ew-logo.png'
import background from './resources/black-bg.jpg'
import ReactCodeInput from 'react-code-input';
import CountdownDisplay from './CountdownDisplay';
import TriviaQuestions from './TriviaQuestions';

const PREFIX = 'BrandedPage';

const classes = {
    root: `${PREFIX}-root`,
    main: `${PREFIX}-main`,
    background: `${PREFIX}-background`,
    logoBox: `${PREFIX}-logoBox`,
    teamLogo: `${PREFIX}-teamLogo`,
    headerLogo: `${PREFIX}-headerLogo`,
    card: `${PREFIX}-card`,
    continue: `${PREFIX}-continue`,
    continueBack: `${PREFIX}-continueBack`,
    appbar: `${PREFIX}-appbar`,
    fixedHeader: `${PREFIX}-fixedHeader`,
    fixedFooter: `${PREFIX}-fixedFooter`,
    white: `${PREFIX}-white`,
    titleBox: `${PREFIX}-titleBox`,
    margin: `${PREFIX}-margin`,
    buttonRow: `${PREFIX}-buttonRow`,
    buttonBox: `${PREFIX}-buttonBox`,
    buttonProgress: `${PREFIX}-buttonProgress`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [`& .${classes.root}`]: {
        height: '100%',
        width: '100%',
    },
    [`& .${classes.main}`]: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        // background: `url(${background})`,
        // backgroundSize: 'cover',
        // background: 'rgba(21,27,23)',
        background: 'black',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1
    },
    [`& .${classes.background}`]: {
        background: `url(${background})`,
        // background: 'rgba(21,27,23)',
        backgroundSize: 'cover',
        flex: 1
    },
    [`& .${classes.logoBox}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    [`& .${classes.teamLogo}`]: {
        height: '100%',
        maxHeight: '150px'
    },
    [`& .${classes.headerLogo}`]: {
        height: '100%',
        maxHeight: '80px'
    },
    [`& .${classes.card}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        width: '55%',
        minWidth: '400px',
        maxWidth: '800px',
        minHeight: '300px'
    },
    [`& .${classes.continue}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        zIndex: 1002,
    },
    [`& .${classes.continueBack}`]: {
        zIndex: 1001,
        position: 'absolute',
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(12px)'
    },
    [`& .${classes.appbar}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem 2rem 1rem 2rem',
        boxSizing: 'border-box',
        zIndex: 1000
    },
    [`& .${classes.fixedHeader}`]: {
        position: 'static',
        top: 0,
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.fixedFooter}`]: {
        position: 'static',
        bottom: 0,
        width: '100%',
        // height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.white}`]: {
        color: 'white'
    },
    [`& .${classes.titleBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    [`& .${classes.margin}`]: {
        margin: '1rem'
    },
    [`& .${classes.buttonRow}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    [`& .${classes.buttonBox}`]: {
        position: 'relative'
    },
    [`& .${classes.buttonProgress}`]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    [`& .${classes.content}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        height: '100%',
        width: '95%',
        margin: 'auto',
        // minWidth: '300px',
        // maxWidth: '800px',
        color: 'white',
        position: 'relative'
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    }
});

const logos = {
    'SEA': seasiLogo,
    'PFM': pfmLogo,
    'VTV': vvtvLogo,
    'EWX': ewLogo,
    'CPT': seasiLogo,
}

const BrandedPage = React.memo(({
    team = 'SEA',
    ...props
}) => {


    return (
        (<Root>
            <div className={classes.fixedHeader}>
                <img alt="logo" src={logos[team]} className={classes.headerLogo} />
            </div>
            <div className={classes.background}>
                <div className={classes.content}>
                    {props.children}
                </div>
            </div>
            <div className={classes.fixedFooter}>
                <Typography className={classes.white} variant="caption">&copy; {moment().format('YYYY')} Screen Engine/ASI</Typography>
            </div>
        </Root>)
    );
    // }
})

export default BrandedPage;