import React, { useState, useRef, useEffect, useMemo, useLayoutEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import KeyboardEventHandler from 'react-keyboard-event-handler'
import ReactPlayerLoader from '@brightcove/react-player-loader'
import { useLocalStorage } from './hooks';
// import './BrightcoveVideoPlayer.css'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import CountdownDisplay from './CountdownDisplay';
import { Card, Typography, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import TriviaQuestions from './TriviaQuestions';
import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { useKinesisWebRTC } from './useKinesisWebRTC';
// import { WatchForRemoval } from 'react-mutation-observer';
import Dial from './dial/Dial'
import DialViewer from './DialViewer';
import EmbeddedSlider from './EmbeddedSlider';
import BrightcoveVideoPlayer from './BrightcoveVideoPlayer';
import YoutubeVideoPlayer from './YoutubeVideoPlayer';
import TicktBoxVideoPlayer from './TicktBoxVideoPlayer';
import background from './resources/black-bg.jpg'
import ButtonReasonDialog from './ButtonReasonDialog';
import config from './config.json'
import ChatPopup from './chime/ChatPopup';
import ContentPresenter from './ContentPresenter';
import { AspectRatioProvider } from './AspectRatioContext';
import BrandedPage from './BrandedPage';
import SessionInfoBanner from './SessionInfoBanner';
import SystemChecker from './SystemChecker';
import VideoPage from './VideoPage';
import BreakDialog from './BreakDialog';

const PREFIX = 'AppTest';

const classes = {
    fullscreen: `${PREFIX}-fullscreen`,
    root: `${PREFIX}-root`,
    background: `${PREFIX}-background`,
    logoBox: `${PREFIX}-logoBox`,
    teamLogo: `${PREFIX}-teamLogo`,
    headerLogo: `${PREFIX}-headerLogo`,
    card: `${PREFIX}-card`,
    continue: `${PREFIX}-continue`,
    continueBack: `${PREFIX}-continueBack`,
    appbar: `${PREFIX}-appbar`,
    fixedHeader: `${PREFIX}-fixedHeader`,
    fixedFooter: `${PREFIX}-fixedFooter`,
    white: `${PREFIX}-white`,
    titleBox: `${PREFIX}-titleBox`,
    margin: `${PREFIX}-margin`,
    buttonRow: `${PREFIX}-buttonRow`,
    buttonBox: `${PREFIX}-buttonBox`,
    buttonProgress: `${PREFIX}-buttonProgress`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`,
    customOverlay: `${PREFIX}-customOverlay`
};

const StyledFullScreen = styled(FullScreen)(({wmOpacity, background}) => ({
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        background: 'black',
        // background: `url(${background})`,
        backgroundSize: 'cover',
        position: 'relative',
        zIndex: 1
    },
    [`& .${classes.background}`]: {
        background: `url(${background})`,
        // background: 'rgba(21,27,23)',
        backgroundSize: 'cover',
        flex: 1
    },
    [`& .${classes.logoBox}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    [`& .${classes.teamLogo}`]: {
        height: '100%',
        maxHeight: '150px'
    },
    [`& .${classes.headerLogo}`]: {
        height: '100%',
        maxHeight: '80px'
    },
    [`& .${classes.card}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        width: '55%',
        minWidth: '400px',
        maxWidth: '800px',
        minHeight: '300px'
    },
    [`& .${classes.continue}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        zIndex: 1002,
    },
    [`& .${classes.continueBack}`]: {
        zIndex: 1001,
        position: 'absolute',
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(12px)'
    },
    [`& .${classes.appbar}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem 2rem 1rem 2rem',
        boxSizing: 'border-box',
        zIndex: 1000
    },
    [`& .${classes.fixedHeader}`]: {
        position: 'static',
        top: 0,
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.fixedFooter}`]: {
        position: 'static',
        bottom: 0,
        width: '100%',
        // height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.white}`]: {
        color: 'white'
    },
    [`& .${classes.titleBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    [`& .${classes.margin}`]: {
        margin: '1rem'
    },
    [`& .${classes.buttonRow}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    [`& .${classes.buttonBox}`]: {
        position: 'relative'
    },
    [`& .${classes.buttonProgress}`]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    [`& .${classes.content}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        height: '100%',
        width: '95%',
        margin: 'auto',
        // minWidth: '300px',
        // maxWidth: '800px',
        color: 'white',
        position: 'relative'
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto',
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    },
    // video: {
    //     position: 'absolute',
    //     height: '100vh',
    //     width: '100vw',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     top: 0,
    //     left: 0,
    //     backgroundColor: '#151515'
    // },
    [`& .${classes.customOverlay}`]: {
        // color: 'white !important',
        backgroundColor: 'transparent !important',
        height: '140% !important',
        width: '140% !important',
        marginTop: 'auto !important',
        marginBottom: 'auto !important',
        left: '0 !important',
        marginLeft: '0 !important',
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        opacity: (wmOpacity && `${wmOpacity / 100} !important`) || '0.15 !important',
        // fontSize: 'calc(9px + (140 - 9) * ((100vw - 150px) / (2400 - 150))) !important',
        userSelect: 'none !important',
        MozUserSelect: 'none !important',
        WebkitUserSelect: 'none !important',
        msUserSelect: 'none !important',
        transform: 'translateX(-15%) translateY(-50%) rotate(-20.5deg) !important',
        // pointerEvents: 'none !important'
    }
}));

let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjo4MjEwLCJzaWQiOjEzMH0sImlhdCI6MTYyMjIzNTk3MSwiZXhwIjoxNjI1MTE1OTcxfQ.BOCNRcCbv9dJ8fBrohOudz7kRO7YZg9-vKIJ4aHEs4w'

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}


let observer = new MutationObserver(mutations => {
    mutations.forEach(function (mutation) {
        console.log(mutation)
        // if (mutation.removedNodes.length) {
        // //   console.log('mutation', mutation, mutation.target.id);
        //   console.log('deleted from node: ' + mutation.target.tagName + '[' + (mutation.target.id || '') + ']')
        // }
    });
});

const getTicktBoxStream = async (videoId, drmType, token) => {
    try {
        const res = await fetch(`https://${config.rest.ticktboxVideoAPI}/stream?videoId=${videoId}&drmType=${drmType}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const data = await res.json()
        return data
    } catch (err) {
        console.log(err)
        return {}
    }
}

const techCheck = false
const now = Date.now()
const limitTuneOut = true
const startTuneOutTime = 0
const stopTuneOutTime = 1
const videoLength = 60 * 3 * 1000
const sessionId = "0";
const dialStyle = 'Emoji';
const DEFAULT_STREAM =
  "https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8";
// const hasOnScreenDial = true

const AppTest = React.memo((props) => {
    const [videoIndex, setVideoIndex] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [showVideo, setShowVideo] = useState(!techCheck)
    const [videoCheckStatus, setVideoCheckStatus] = useState({})
    const [ratio, setRatio] = useState()
    const [resetDial, setResetDial] = useState(false);
    const [showDial, setShowDial] = useState(false)
    const [sendDialData, setSendDialData] = useState(false)
    const [buttonReasonScore, setButtonReasonScore] = useState()
    const [breakDataDialog, setBreakDataDialog] = useState();
    const [breakCount, setBreakCount] = useLocalStorage(`breakcount${sessionId}-${videoIndex || 0}-${techCheck ? '-TC' : ''}`, 0)

    const [showButton, setShowButton] = useState({
        'R': !(limitTuneOut && !!startTuneOutTime)
    })

    const fullScreenMode = useFullScreenHandle();

    const symbolOptions = [{
        emoji: '😊',
        tooltip: 'Happy'
    }, {
        emoji: '🥹',
        tooltip: 'Crying'
    }, {
        emoji: '🥺',
        tooltip: 'Sad'
    }, {
        emoji: '😂',
        tooltip: 'LOL'
    }, {
        emoji: '🤣',
        tooltip: 'ROFL'
    }, {
        emoji: '🤩',
        tooltip: 'Amazing'
        // }, {
        //     emoji: '🤮',
        //     tooltip: 'Disgusting'
        // }, {
        //     emoji: '😱',
        //     tooltip: 'OMG'
        // }, {
        //     emoji: '🤔',
        //     tooltip: 'Amusing'
        // }, {
        //     emoji: '😬',
        //     tooltip: 'Cringy'
    },];

    const inputTime = 1; // seconds
    const includeShoutOuts = true;
    const includeBreaks = true;
    const maxBreaks = 3;
    const feedbackWindowBottomRight = true;

    const videoDataRef = useRef()
    const showButtonRef = useRef(showButton)
    const buttonShowTriggered = useRef({})

    useEffect(() => {
        showButtonRef.current = showButton
    }, [showButton])

    // const { isOnline } = useIsOnline()

    // useEffect(() => {
    //     console.log('ARE WE ONLINE? ', isOnline)
    // }, [isOnline])



    // const playerRef = useRef()
    // const buttonReasonRef = useRef()

    // useEffect(() => {
    //     browserCheck().then((browser) => {
    //         console.log(browser)
    //     })
    //     if (document[hidden] || !document.hasFocus()) console.log('CANNOT SEE VIDEO!!!', document[hidden], !document.hasFocus())
    // }, [])

    useEffect(() => {
        const noRightClick = (ev) => {
            ev.preventDefault()
            console.log('no right click allowed')
        }
        const notWatching = (ev) => {
            // ev.preventDefault()
            console.log('not watching? ', document[hidden] || ev.type === 'blur')
        }
        const handleUnload = (ev) => {
            return "TESTEST"
            console.log('hello')
        }
        // window.addEventListener('contextmenu', noRightClick)
        // window.addEventListener(visibilityChange, notWatching)
        window.addEventListener('blur', notWatching)
        window.addEventListener('focus', notWatching)
        window.addEventListener('keyup', (ev) => {
            if (ev.keyCode === 44) {
                ev.preventDefault()
                return
                // ev.clipboardData.setData('text', 'Print Screen Disabled')
            }
        })
        window.addEventListener('beforeunload', handleUnload)
        const firstInteraction = (ev) => {
            console.log('FIRST INTERACTION', ev)
            window.removeEventListener('click', firstInteraction)
        }
        window.addEventListener('click', firstInteraction)
        return () => {
            window.removeEventListener('contextmenu', noRightClick)
            // window.removeEventListener(visibilityChange, notWatching)
            window.removeEventListener('blur', notWatching)
            window.removeEventListener('focus', notWatching)
            window.removeEventListener('unload', handleUnload)
        }
    }, [])

    const handleVideoStart = useCallback(() => {
        const now = Date.now()
        const videoTime = videoDataRef?.current?.getVideoPosition()
        console.log(JSON.stringify({
            action: 'status',
            userStatus: 'Watching',
            userSubstatus: null,
            videoTime,
            viewerTime: now,
        }));
    }, [])

    const handleStartDial = useCallback(() => {
        setSendDialData(true)
    }, [])

    const handlePauseDial = useCallback(() => {
        setSendDialData(false)
    }, [])

    const handleScoreChange = useCallback((scoreData) => {
        const currentTime = videoDataRef?.current?.getVideoPosition()
        console.log('score change', currentTime, scoreData)
        const buttonPressScore = ['R', 'B-S', 'S'].includes(scoreData.button)
        if (buttonPressScore) {
            setButtonReasonScore(scoreData)
        }
    }, [])

    const sendButtonPressReason = useCallback((newScoreData) => {
        console.log('button press reason', newScoreData)
        // if break start, open new dialog to end break
        if (newScoreData.button === 'B-S') setBreakDataDialog(newScoreData);
        setButtonReasonScore()
    }, [buttonReasonScore])

    const sendBreakData = useCallback((newData) => {
        console.log('break reason:', newData);
        setButtonReasonScore();
        // setBreakDataDialog(false);
    }, [])

    const setVideoAspectRatio = useCallback((aspectRatio) => {
        console.log('GOT ASPECT RATIO')
        setRatio(aspectRatio)
    }, [])

    useEffect(() => {
        console.log('SHOWING DIAL?', !!ratio)
        if (ratio) setShowDial(true)
    }, [ratio])

    const videoStreamData = useCallback(async (drmType) => {
        const status = await getTicktBoxStream('test15', drmType, token)
        console.log('GOT TB STREAM DATA', status)
        return status
    }, [])

    const onVideoTimeChange = useCallback((event) => {
        if (limitTuneOut && (startTuneOutTime || stopTuneOutTime)) {
            const time = event.target.player ? event.target.player.currentTime() * 1000 : event.target.currentTime * 1000;
            if (showButtonRef.current['R'] && stopTuneOutTime && (videoLength - (stopTuneOutTime * 60000) <= time)) {
                setShowButton(prev => ({ ...prev, 'R': false }))
            } else if (!(showButtonRef.current['R'] || buttonShowTriggered.current['R']) && startTuneOutTime && (startTuneOutTime * 60000 <= time)) {
                buttonShowTriggered.current = { ...buttonShowTriggered.current, 'R': true }
                setShowButton(prev => ({ ...prev, 'R': true }))
            }
        }
    }, []);

    const toggleFullScreen = useCallback((event) => {
        fullScreenMode.active ? fullScreenMode.exit() : fullScreenMode.enter()
    }, [fullScreenMode]);

    const handleShowVideo = useCallback(() => {
        console.log('SHOWING VIDEO NOW')
        setShowVideo(true)
    }, [])

    const handleVideoError = useCallback((error) => {
        // unloadRef.current && window.removeEventListener('beforeunload', handleUnload)
        if (techCheck) {
            setShowVideo(false)
            setVideoCheckStatus({
                error
            })
        } else {
            window.location.reload()
        }
    }, [])

    const handleVideoComplete = useCallback((advanceIndex) => {
        console.log('VID COMPLETE.......', advanceIndex)
        // const requestId = short.generate()
        // if (!session.techCheck && (session.hasSurvey && session.surveyPlacement !== 'pre') && session.role === 'Viewer') {
        //   let surveyUrl;
        //   switch (session.surveyType) {
        //     case 'Decipher':
        //       surveyUrl = `https://survey.everyonesez.com/survey/${session.surveyId}?list=1&sessionid=${session.sessionId}&id=${session.userId}&postrequestid=${requestId}`
        //       break;
        //     case 'Qualtrics':
        //     default:
        //       surveyUrl = `https://screenengine.qualtrics.com/jfe/form/${session.surveyId}?sessionid=${session.sessionId}&id=${session.userId}&postrequestid=${requestId}`
        //       break;
        //   }
        //   if (session.surveyPlacement === 'both') surveyUrl += `&state=${session.surveyToken}`
        //   sendMessage(JSON.stringify({
        //     action: 'status',
        //     userStatus: 'Post-Survey',
        //     userSubstatus: null,
        //     finished: true,
        //     requestId
        //   }));
        //   window.location = surveyUrl
        // } else 
        if (techCheck) {
            setShowVideo(false)
            setVideoCheckStatus({
                complete: true
            })
        } else if (!!advanceIndex) {
            console.log('NEXT VIDEO')
            setCurrentIndex(prev => (prev || 0) + 1)
            setTimeout(() => {
                setVideoIndex(prev => (prev || 0) + 1)
            }, 5000)
            // setResetDial({})
        } else {
            console.log('SESSION IS COMPLETE!')
            //   sendMessage(JSON.stringify({
            //     action: 'status',
            //     userStatus: 'Complete',
            //     userSubstatus: null,
            //     finished: true,
            //     requestId
            //   }));
            //   navigate('/complete', {state: { sessionid: session.sessionId, id: session.userId, requestid: requestId, redirect: true, team: session.team, scheduledStartTime: session.scheduledStartTime, videoTitle: session.videoTitle, techCheck: session.techCheck }})
        }
    }, [videoIndex])

    const handleStatusChange = useCallback((status) => {
        console.log('STATUS CHANGE! - ', status)
    }, [])

    return (
        <StyledFullScreen handle={fullScreenMode} wmOpacity={'35'} background={background}>
            <div className={classes.root}>
                {/* <SystemChecker
                    hidden={showVideo}
                    startSessionFlow={handleShowVideo}
                    sessionFlowStatus={videoCheckStatus}
                    onStatusChange={handleStatusChange}
                    allowedBrowsers={["Chrome"]}
                    // onComplete={handleTechCheckComplete}
                    // techCheckComplete={(session.state === 'Approved' || session.state === 'Waitlisted') || techCheckComplete}
                    // isApproved={(session.state === 'Approved') || techCheckApproved}
                    checkInTime={"03-31-2022 12:00:00Z"}
                /> */}
                {/* <VideoPage
                    disableInteraction={!showVideo}
                    token={token}
                    sessionId={'0'}
                    userId={'0'}
                    socketConnected={true}
                    encryptedSessionId={'ABC123'}
                    showPreSurvey={false}
                    surveyRequestId={null}
                    sendMessage={console.log}
                    videoDataRef={videoDataRef}
                    techCheck={techCheck}
                    selfGuided={true}
                    guestEjected={false}
                    email={'kspierson@gmail.com'}
                    role={'Viewer'}
                    chimeUsername={'ken@seasi.dev'}
                    smsSent={false}
                    smsError={false}
                    resend={false}
                    verified2FA={false}
                    error2FA={false}
                    hasChat={false}
                    started={false}
                    sessionStarted={true && showVideo}
                    sessionEnded={false}
                    fadeOut={false}
                    autoStop={false}
                    videoStopTime={null}
                    scheduledStartTime={now}
                    videoTitle={'THIS IS A TEST'}
                    team={'PFM'}
                    symbolOptions={symbolOptions}
                    inputTime={inputTime}
                    isLiveSession={false}
                    showCountdown={false}
                    showQuiz={false}
                    chimeChatInstance={null}
                    chimeChatChannels={[]}
                    videoPlatform={'Brightcove'}
                    playerId={'VBdQt9xaK'}
                    // videoId={'6240805119001'}
                    videoId={'1754615556955048065'}
                    multiSection={true}
                    videoIndex={videoIndex}
                    currentIndex={currentIndex}
                    techCheckVideoId={'6217864765001'}
                    include2fa={false}
                    override2fa={false}
                    watermarkOpacity={15}
                    watermarkStyle={'Tiled'}
                    surveyToken={null}
                    hasSurvey={false}
                    surveyPlacement={null}
                    surveyType={null}
                    surveyId={null}
                    videoTime={null}
                    sessionStartTime={techCheck ? null : new Date()}
                    resetDial={resetDial}
                    viewerTime={now}
                    serverTime={now}
                    videoLength={videoLength}
                    unloadTime={null}
                    isDialSession={true}
                    dialLinkSent={false}
                    dialLinkError={false}
                    connectedDial={false}
                    resendDial={null}
                    dialMotion={'Stepwise'}
                    numberOfMeters={19}
                    sliderKnob={'Mickey Ears'}
                    sliderKnobColor={'red'}
                    includeTuneOut={true}
                    hasOnScreenDial={true}
                    limitTuneOut={limitTuneOut}
                    inactiveReminder={true}
                    inactiveInterval={120}
                    inactiveMessage={'MOVE YOUR DIAL'}
                    noDialArrowKeys={false}
                    dialStyle={'Emoji'}
                    gaugeStyle={null}
                    startTuneOutTime={null}
                    stopTuneOutTime={null}
                    askTuneOutReason={true}
                    maxReasonLength={140}
                    maxReasonTime={45}
                    scoreBase={1000}
                    allowFullscreen={true}
                    isFullscreen={!!(fullScreenMode?.active)}
                    toggleFullScreen={toggleFullScreen}
                    onVideoError={handleVideoError}
                    onVideoComplete={handleVideoComplete}
                /> */}
                {/* <BrandedPage>
                    <SessionInfoBanner videoTitle="TESTING 123" />
                    HELLO WORLD!
                </BrandedPage> */}
                <AspectRatioProvider>
                    <ContentPresenter
                        token={token}
                        sessionId={sessionId}
                        userId={'0'}
                        videoPlatform={'IVS'}
                        playerId={'VBdQt9xaK'}
                        // videoId={'6240805119001'}
                        videoId={DEFAULT_STREAM}
                        techCheck={false}
                        fadeOut={false}
                        autoStop={false}
                        videoStopTime={null}
                        wm={'kspierson@gmail.com'}
                        wmOpacity={15}
                        watermarkStyle={null}
                        symbolOptions={symbolOptions}
                        alreadyStarted={true}
                        sessionStartTime={Date.now()}
                        videoTime={null}
                        videoDuration={videoLength}
                        unloadTime={null}
                        isLive={false}
                        allowFullscreen={true}
                        isFullscreen={!!(fullScreenMode?.active)}
                        toggleFullScreen={toggleFullScreen}
                        videoDataRef={videoDataRef}
                        handleVideoStart={handleVideoStart}
                        handleVideoError={handleVideoError}
                        handleUnload={null}
                        handleRedirect={null}
                        // handleNotWatching={handleNotWatching}
                        hasOnScreenDial={true}
                        hasEmojiDial={false}
                        hasOnlyButtons={false}
                        dialStyle={dialStyle}
                        maxScore={1000}
                        dialMotion={'Stepwise'}
                        numberOfMeters={19}
                        sliderKnob={'Mickey Ears'}
                        sliderKnobColor={'red'}
                        includeTuneOut={true}
                        limitTuneOut={limitTuneOut}
                        includeShoutOuts={includeShoutOuts}
                        includeBreaks={includeBreaks}
                        breakCount={breakCount}
                        setBreakCount={setBreakCount}
                        maxBreaks={maxBreaks}
                        inactiveReminder={true}
                        inactiveInterval={120}
                        inactiveMessage={'MOVE YOUR DIAL'}
                        noDialArrowKeys={false}
                        sendData={sendDialData && !buttonReasonScore}
                        resetDial={false}
                        handleStartDial={handleStartDial}
                        handlePauseDial={handlePauseDial}
                        handleScoreChange={handleScoreChange}
                    />
                </AspectRatioProvider>
            </div>
            {/* <div className={classes.root}> */}
            {/* <DialViewer sendData={false} sessionId={0} dialMotion="Stepwise" numberOfMeters={19} onScoreChange={() => {}} show={!!ratio} viewRatio={ratio} maxScore={100} dialStyle={'asi'} dialColor={'#323232'} currentTheme={'light'} maxAngle={270} useShapes={true} isOn={true} /> */}
            {/* <div style={{
                    // height: 0
                    // width: '100%',
                    position: 'relative',
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',f
                    alignItems: 'center',
                    justifyContent: (hasOnScreenDial) ? 'flex-end' : 'center',

                }}> */}
            {/* <YoutubeVideoPlayer videoRef={playerRef} fluid={false} fill={true} fullscreen={false} onMetadataLoaded={setVideoAspectRatio} videoId='9LuoWFHTm2I' onStarted={handleVideoStart} allowFullscreen={true} toggleFullScreen={toggleFullScreen} isFullscreen={!!(fullScreenMode?.active)} hasOnScreenDial={hasOnScreenDial} /> */}
            {/* <BrightcoveVideoPlayer videoRef={playerRef} style={{ maxWidth: ratio ? `${1 / ratio * 100}vh` : 0, maxHeight: ratio ? `${ratio * 100}vw` : 0, width: ratio ? '100%' : 0, height: ratio ? '100%' : 0, flex: 1 }} fluid={false} fill={true} fullscreen={false} onMetadataLoaded={setVideoAspectRatio} wm={'kspierson@gmail.com'} wmOpacity={15} watermarkStyle="Tiled" playerId='VBdQt9xaK' videoId='6240805119001' sessionId={'0'} onBuffer={handlePauseDial} onPlay={handleStartDial} alreadyStarted={true} videoTime={20} unloadTime={Date.now()} sessionComplete={false} onTimeUpdate={limitTuneOut ? onVideoTimeChange : undefined} allowFullscreen={true} toggleFullScreen={toggleFullScreen} isFullscreen={!!(fullScreenMode?.active)} hasOnScreenDial={hasOnScreenDial} /> */}
            {/* <TicktBoxVideoPlayer videoRef={playerRef} style={{ maxWidth: ratio ? `${1 / ratio * 100}vh` : 0, maxHeight: ratio ? `${ratio * 100}vw` : 0, width: ratio ? '100%' : 0, height: ratio ? '100%' : 0, flex: 1 }} fluid={false} fill={true} fullscreen={false} onMetadataLoaded={setVideoAspectRatio} wm={'kspierson@gmail.com'} wmOpacity={15} videoStreamData={videoStreamData} onStarted={handleVideoStart} onBuffer={handlePauseDial} onPlay={handleStartDial} alreadyStarted={true} sessionStartTime={Date.now()} videoTime={20} unloadTime={Date.now()} sessionComplete={false} onTimeUpdate={limitTuneOut ? onVideoTimeChange : undefined} allowFullscreen={true} toggleFullScreen={toggleFullScreen} isFullscreen={!!(fullScreenMode?.active)} hasOnScreenDial={hasOnScreenDial} /> */}
            {/* </div>
                {hasOnScreenDial && <EmbeddedSlider sendData={sendDialData} sliderKnob="Mickey Ears" sessionId={'1e'} dialMotion="Stepwise" numberOfMeters={19} onScoreChange={handleScoreChange} show={showDial} viewRatio={ratio} maxScore={1000} includeTuneOut={showButton['R'] && true} />}
            </div> */}
            {/* <ChatPopup /> */}
            {includeBreaks && <BreakDialog feedbackWindowBottomRight={feedbackWindowBottomRight} onSubmit={sendBreakData} videoDataRef={videoDataRef} breakCount={breakCount} setBreakCount={setBreakCount} breakDataDialog={breakDataDialog} setBreakDataDialog={setBreakDataDialog} />}
            <ButtonReasonDialog feedbackWindowBottomRight={feedbackWindowBottomRight} scoreData={buttonReasonScore} maxLength={12} maxTime={100} onSubmit={sendButtonPressReason} />
        </StyledFullScreen>
    );
})

export default AppTest;
