import React, { useState, useRef, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import YouTube from 'react-youtube';
import { useEventListener } from './hooks'
import { IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
// import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { makeStyles } from '@mui/material/styles';
import * as workerTimers from 'worker-timers'

const PREFIX = 'YoutubeVideoPlayer';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  resume: `${PREFIX}-resume`,
  youtube: `${PREFIX}-youtube`,
  player: `${PREFIX}-player`,
  fullscreenButton: `${PREFIX}-fullscreenButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
    width: '35%',
    minWidth: '160px',
    maxWidth: '260px'
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.resume}`]: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    // backgroundColor: '#151515'
  },
  [`& .${classes.youtube}`]: {
    maxWidth: props => props.aspectRatio ? `${1 / props.aspectRatio * 100}vh` : 0,
    maxHeight: props => props.aspectRatio ? `${props.aspectRatio * 100}vw` : 0,
    width: props => props.aspectRatio ? '100%' : 0,
    height: props => props.aspectRatio ? '100%' : 0,
    flex: 1
  },
  [`& .${classes.player}`]: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none'
  },
  [`& .${classes.player} iframe`]: {
    width: '100%',
    height: '100%',
  },
  [`& .${classes.fullscreenButton}`]: {
    // position: 'absolute',
    // bottom: 0,
    // right: 0,
    color: 'white',
    zIndex: 2,
    opacity: 0.25,
    '&:hover': {
      opacity: 1
    },
    mixBlendMode: 'difference'
  }
});

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const youtubeOpts = {
  playerVars: {
    autoplay: 0,
    autohide: 1,
    mute: 1,
    showinfo: 0,
    modestbranding: 1,
    loop: 0,
    fs: 0,
    cc_load_policy: 0,
    iv_load_policy: 3,
    controls: 0
  },
};

const getVideoAspectRatio = async (videoId) => {
  try {
    const res = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`)
    const { height, width } = await res.json()
    return height / width
  } catch (err) {
    console.log(err)
    return null
  }
}

const YoutubeVideoPlayer = React.memo(({ videoRef, fluid, fill, fullscreen, allowFullscreen, isFullscreen, toggleFullScreen, videoId, onStarted, onMetadataLoaded, onVideoLoaded, onBuffer, onPlay, onVisiblityChange, onFinished, onVideoError, isLive, liveStreamStatus, videoStopTime, hasOnScreenDial }) => {
  const [aspectRatio, setAspectRatio] = useState()
  // const [resuming, setResuming] = useState(alreadyStarted)



  const visibilityChangeFn = () => { }

  const playerRef = useRef()
  const timerRef = useRef()
  // const fadeRef = useRef()
  // const volumeRef = useRef(100)

  // useEffect(() => {
  //   if (isLive) {
  //     liveStreamStatus(videoId).then((status) => {
  //       console.log('live event copmleted?', !!status.actualEndTime)
  //       if (!!status.actualEndTime && onFinished) onFinished() 
  //     })
  //   }
  // }, [videoId, isLive, liveStreamStatus, onFinished])

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
    return () => {
      if (timerRef.current) workerTimers.clearInterval(timerRef.current)
    }
  }, [onVisiblityChange])

  // if (onVisiblityChange) {
  useEventListener('blur', onVisiblityChange || visibilityChangeFn, true);
  useEventListener('focus', onVisiblityChange || visibilityChangeFn, true);
  useEventListener(visibilityChange, onVisiblityChange || visibilityChangeFn, true);
  // }

  useEffect(() => {
    if (videoStopTime && playerRef.current) {
      timerRef.current = workerTimers.setInterval(() => {
        const currentTime = playerRef.current.getCurrentTime()
        if (currentTime >= videoStopTime && onFinished) {
          workerTimers.clearInterval(timerRef.current)
          timerRef.current = null
          onFinished()
        }
      }, 1000)
    }
  }, [videoStopTime, onFinished])

  const afterLoaded = useCallback((event) => {
    playerRef.current = event.target
    console.log(playerRef.current)
    if (videoRef) videoRef.current = event.target //to get time data

    getVideoAspectRatio(videoId).then((ratio) => {
      setAspectRatio(ratio)
      if (onMetadataLoaded) onMetadataLoaded(ratio)
    })

    // playerRef.current.tech_.el_.disablePictureInPicture = true
    // playerRef.current.tech_.el_.controls = false
    // playerRef.current.tech_.el_.blur();
    // playerRef.current.tech_.el_.addEventListener('focus', (e) => {
    //   e.currentTarget.blur()
    //   console.log('video focus')
    // })

    // const iframe = playerRef.current.getIframe()
    // console.log('youtube iframe...', iframe, iframe.document)

    // playerRef.current.getIframe().blur();
    // playerRef.current.getIframe().addEventListener('focus', (e) => {
    //   e.currentTarget.blur()
    //   console.log('youtube iframe focus')
    // })

    // playerRef.current.controls(false);
    // if (fullscreen) playerRef.current.enterFullWindow();
    // playerRef.current.autoplay(false)
    // playerRef.current.muted(false)

    // GUEST PLAYER
    if (isLive) {
      liveStreamStatus(videoId).then((status) => {
        console.log('live event copmleted?', !!status.actualEndTime)
        if (!!status.actualEndTime && onFinished) {
          onFinished()
        } else {
          playerRef.current.playVideo()
        }
      })
    } else {
      playerRef.current.playVideo()
    }

    // ADMIN VIEWER
    // if (alreadyStarted) {
    //   playerRef.current.playVideo()
    // }

    playerRef.current.addEventListener('onStateChange', (event) => {
      switch (event.data) {
        case 0:
          console.log('end event')
          if (onFinished) onFinished()
          break;
        case 1:
          console.log('play event')
          playerRef.current.unMute()
          if (onStarted) onStarted()
          if (onPlay) onPlay()
          break;
        case -1:
          console.log('unstarted...')
        case 2:
          console.log('pause event')
          // if (!playerRef.current.ended()) 
          playerRef.current.playVideo()
          break;
        case 3:
          console.log('buffering event')
          if (onBuffer) onBuffer()
          if (isLive) {
            liveStreamStatus(videoId).then((status) => {
              console.log('live event copmleted?', !!status.actualEndTime)
              if (!!status.actualEndTime && onFinished) onFinished()
            })
          }
          break;
        default:
          console.log('state change event', event)
          break;
      }
    })

    playerRef.current.addEventListener('onError', (event) => {
      console.log('error event', event)
      if (onVideoError) onVideoError(event)
    })


    // playerRef.current.on('seeked', () => {
    //   console.log('seeked event')
    // })

    // playerRef.current.on('seeking', () => {
    //   console.log('seeking event')
    // })

    // playerRef.current.on('loadedmetadata', () => {
    //   console.log('loadedmetadata event')
    //   if (onMetadataLoaded) onMetadataLoaded()
    //   // console.log('aspect ratio: ', playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
    // })

    // playerRef.current.on('loadeddata', () => {
    //   console.log('loadeddata event')
    //   if (onVideoLoaded) onVideoLoaded()
    //   setVideoLoaded(true)
    // })

    // playerRef.current.on('durationchange', (e) => {
    //   console.log('durationchange event', e)
    // })

    // playerRef.current.on('waiting', () => {
    //   console.log('waiting event')
    // })

  }, [videoId, isLive, liveStreamStatus, videoRef, onFinished, onBuffer, onMetadataLoaded, onPlay, onStarted, onVideoError])

  return (
    (<Root>
      {/* {resuming && <div className={classes.resume}>
        <div className={classes.continueBack}></div>
        <Card className={classes.continue} elevation={12}>
          {videoLoaded ? <>
            Please click below to {videoTime && unloadTime ? 'resume' : 'join'} session
            <Button className={classes.margin} disabled={!videoLoaded} color='primary' variant='contained' onClick={handleResume}>{videoTime && unloadTime ? 'Resume' : 'Join'}</Button>
          </> : <>
              Please wait...
            <CircularProgress className={classes.margin} />
            </>}
        </Card>
      </div>} */}
      <YouTube className={classes.player} containerClassName={classes.youtube} opts={youtubeOpts} videoId={videoId} onReady={afterLoaded} />
      {(allowFullscreen && !isFullscreen) && <div style={{ position: 'absolute', maxHeight: '100vw', height: aspectRatio ? `calc(${100 * 1 / aspectRatio}%)` : '100vw', maxWidth: '100vh', width: aspectRatio ? `calc(${100 * aspectRatio}%)` : '100vh', transform: hasOnScreenDial ? 'translate(0,50%) rotate(270deg) translate(50%,0)' : 'rotate(270deg)' }}>
        <IconButton className={classes.fullscreenButton} color="inherit" onClick={toggleFullScreen}>
          {/* {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />} */}
          <FullscreenIcon />
        </IconButton>
      </div>}
    </Root>)
  );
})

export default YoutubeVideoPlayer;
